// Simple wrapper to hold page components
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Icon from '../../Components/UI/Icon';
// Redux
import * as actions from '../../Store/Actions';

// This maps Props within Container to Redux State
// Output of component, call functions in the Store->Actions->Function
const mapDispatchtoProps = (dispatch) => {
    
    return {
        // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
        logout: () => dispatch(actions.logout())
    }
}

const Logout = ( props ) => {

    return (
        <Link className="sidebar-link d-flex" to="/login" onClick={props.logout} >
            <Icon icon={props.icon} size={props.size} />
            <span className="link-name">Logout</span>
        </Link>

    );
}

export default connect(null, mapDispatchtoProps)(Logout);