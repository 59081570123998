import React from 'react';
// UI Components
import Button from '../../Components/UI/Buttons';
import ProgressBar from '../../Components/UI/ProgressBar';

const FileTools = (props) => {

    const toolButtons = props.Buttons.map( button => {
        return <Button
                    key={button.name}
                    elementType={button.elementType}
                    elementConfig={button.elementConfig}
                    label={button.label}
                    styling={button.styling}
                    icon={button.icon}
                    toggle={button.toggle}
                    clicked={props.buttonHandler}
                    disabled={props.disabled}
                /> 
    })

    // Upload Button or Progress Bar
    let uploadButton_pBar;
    if (props.uploading) {
        uploadButton_pBar = <ProgressBar pBarWidth={props.pBarWidth} message={props.message} /> 
    }
    else uploadButton_pBar = <Button
                                elementType={props.UploadButton.elementType}
                                elementConfig={props.UploadButton.elementConfig}
                                label={props.UploadButton.label}
                                styling={props.UploadButton.styling}
                                icon={props.UploadButton.icon}
                                toggle={props.UploadButton.toggle}
                                clicked={props.buttonHandler}
                            />

    return (
        <div className="row filetools">
            <div className="col-md-12 col-lg-9 col-xl-6 d-flex">
                {toolButtons}
            </div>
            <div className="col-md-12 col-lg-3 col-xl-6 d-flex flex-row-reverse">
                {uploadButton_pBar}
            </div>
        </div>
    )
}

export default FileTools;