import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import ProfileState from '../StateConfiguration/ProfileState';
import Icon from '../../Components/UI/Icon';
import UserCard from '../../Components/Cards/UserCard';
// Redux Actions
import * as actions from '../../Store/Actions/index';

class Profile extends Component {
    appName = 'profile/';
    LIST_CLIENT = 'listClient';

    state = ProfileState;

    componentDidMount() {
        // RWC - Only check Authentication in the Side Nav Toolbar
        // Mounted, Authenticated and NOT Loading, time to retrieve Files/Folders
        if ( this.props.authenticated) {
            this.AxiosGETHandler(this.LIST_CLIENT);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // Initialize the Booleans
        let loadingTransition = false;
        let initComplete = false;

        // Booleans
        // Loading transition has occurred
        loadingTransition = (nextProps.loading && !this.props.loading) || (!nextProps.loading && this.props.loading);
        // Just initialized
        initComplete = nextProps.initialized;
        // Selected new User

        // Condition to determine if Updating is needed
        if ( loadingTransition )
            return true
        else if ( initComplete )
            return true
        else 
            return false
    }

    componentDidUpdate(prevProps, prevState) {
        // ONLY call AWS if we are authenticated
        if (this.props.authenticated) {
            // Axios call conditions
            // 1. No Contents - we just refreshed
            if (prevProps.initialized === false && this.props.initialized === true && !this.props.loading) {
                this.AxiosGETHandler(this.LIST_CLIENT);
            }
        }
    }

    // RWC - Simple help function to clean up Object returned by Cognito
    FormatUser = (user) => {
        user.UserAttributes.forEach( attribute => {
            user[attribute.Name] = attribute.Value;
        });
        return user
    }

    AxiosGETHandler = (action) => {
        const queryParam = '?action=' + action + '&username=' + this.props.userID;
        axios.get(this.appName+queryParam, {headers: {Authorization: this.props.idToken}}).then( (response) => {
                this.props.onLoad(true);
                const Client = this.FormatUser(response.data);
                this.setState( {...this.state, User: Client}, () => this.props.onLoad(false) );  
            })
            .catch( (error) => {
                console.log(error.message);
            })
    }

    render () {

        return (
            <div className="container-fluid">
                <div className="row no-gutters p-3">
                    <div className="col-lg-12">
                        <div className="page-header-title">
                            <Icon icon="Database" size="2x"/>
                            <div className="page-title d-inline">
                                <h5>User Account</h5>
                                <span className="page-description">User profile information</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters p-3">  
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="col">
                                <UserCard 
                                    User={this.state.User}
                                    Edit={this.state.Edit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        initialized: state.initialization.initialized,
        loading: state.loading.load,
        idToken: state.authenticate.idToken,
        userID: state.authenticate.userID,
        authenticated: state.authenticate.idToken !== null,
        groups: state.authenticate.groups,
    }
}
// This maps Props within Container to Redux State
// Output of component, call functions in the Store->Actions->Function
const mapDispatchtoProps = (dispatch) => {
    return {
    // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
        onLoad: (loading) => dispatch(actions.Loading(loading))
    }
}
export default connect(mapStatetoProps, mapDispatchtoProps)(Profile);