import React from 'react';
import Button from './Buttons';
import Icon from './Icon';

const Modal = ({ModalConfig, ShowModal, SelectedFile, buttonHandler, changeHandler}) => {

    let Modal=null;
    let Style=null;

    if (ShowModal) {
        Style={"display": "block"};
        Modal="modal fade show";
    }
    else {
        Modal="modal fade";
        Style={ "display": "none"};
    }
    let icon = null;
    let title = null;
    let message = null; 
    let filename = null;
    let modalButtons = null;
    let Form = null;
   

    if (ModalConfig !== null) {
         modalButtons = ModalConfig.buttons.map( button => {
            return <Button
                    key={button.name}
                    elementType={button.elementType}
                    elementConfig={button.elementConfig}
                    label={button.label}
                    styling={button.styling}
                    icon={button.icon}
                    clicked={(event,key,label) => buttonHandler(event, null, label)}
                    />
        })
        icon = <Icon icon={ModalConfig.icon} style={ModalConfig.style}/>;
        title =  ModalConfig.title;
        message = ModalConfig.message;
        filename = SelectedFile.name

        if (ModalConfig.title === 'Rename File' || ModalConfig.title === 'Create Folder') {
            Form = (
                <form >
                    <div className="form-group">
                        <label className="col-form-label">New File Name:</label>
                        <input type="text" className="form-control modal-input" onChange={(event) => changeHandler(event) } />
                    </div>
                </form>
            )
        }
    }

    return (
        <div className={Modal} style={Style} tabIndex="-1">
            <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {icon}
                        {title}
                    </h5>
                    <Icon icon="Times" iconButton={true} Class="x-close" name="close" clicked={(event,key,label) => buttonHandler(event, null, label)} />
                </div>
                <div className="modal-body">
                {message} {filename}
                </div>
                <div className="modal-body">
                    {Form}
                </div>
                <div className="modal-footer">
                    {modalButtons}
                </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;