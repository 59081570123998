// Rambeau Cloud Register In Page
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Input from '../../Components/UI/Input';

import * as actions from '../../Store/Actions/index';

class Register extends Component {

    state = {
        controls: {
            firstname: { 
                elementType: 'input', 
                elementConfig: { type: 'text', placeholder: 'First' } , 
                value: '',
                validation: { required: 'true'},
                valid: false,
                touched: false
            },
            lastname: { 
                elementType: 'input', 
                elementConfig: { type: 'text', placeholder: 'Last' } , 
                value: '',
                validation: { required: 'true'},
                valid: false,
                touched: false
            },
            username: { 
                elementType: 'input', 
                elementConfig: { type: 'text', placeholder: 'Username' } , 
                value: '',
                validation: { required: 'true'},
                valid: false,
                touched: false
            },
            email: { 
                elementType: 'input', 
                elementConfig: { type: 'email', placeholder: 'Email' } , 
                value: '',
                validation: { required: 'true'},
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input', 
                elementConfig: { type: 'password', placeholder: 'Password' } , 
                value: '',
                validation: { required: 'true', minLength: 8 },
                valid: false,
                touched: false
            },
        }, 
    }

    onChangeHandler (event, id) {
        //Copy the state
        const updatedControls = {...this.state.controls};
        //Copy the specific control using the id
        const updatedElement = {...updatedControls[id]};
        //Update just the value of that control
        updatedElement.value = event.target.value;
        //Copy the updated value back to the controls
        updatedControls[id] = updatedElement;
        this.setState({...this.state, controls: updatedControls});
    }

    RegisterHandler = (event) => {
        //Block Default
        event.preventDefault();
        // Call the Cognito Sign in component
        this.props.register(    this.state.controls.username.value,
                                this.state.controls.firstname.value,
                                this.state.controls.lastname.value,
                                this.state.controls.email.value,
                                this.state.controls.password.value);
    }

    render () {
        const formElementsArray = [];

        for (let key in this.state.controls) {
            formElementsArray.push( {id: key, config: this.state.controls[key]} )
        }

        const form = formElementsArray.map(
            formElement => ( <Input 
                key={formElement.id} 
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value} 
                changed={ (event) => this.onChangeHandler(event, formElement.id) } />
            )
        )

        //Redirect After Register Success
        let registerRedirect = null;
        if (this.props.userID) {
            console.log(this.props.userID);
            //this.props.history.push('/verify');
            registerRedirect = <Redirect to ="/verify" />
        }

        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4">
                        <div className="card bg-primary text-center card-form">
                            <div className="card-body">
                            <h3> Rambeau Cloud </h3>
                            <p> Register </p>
                            {registerRedirect}
                            <form onSubmit={this.RegisterHandler}>
                                {form}
                                <br></br>
                                <button type="submit" className="btn btn-outline-light btn-block" disabled={this.state.loading}>Submit</button>
                            </form>
                            <br></br>
                            <p>Have an invite code? Join</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        loading: state.authenticate.loading,
        error: state.authenticate.error,
        userID: state.authenticate.userID
    }
}

//This maps Props within Container to Redux State
// Output of component, call functions in the Store->Actions->Function
const mapDispatchtoProps = (dispatch) => {
    return {
        // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
        register: (username, firstname, lastname, email, password) => dispatch(actions.register(username, firstname, lastname, email, password))
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Register);