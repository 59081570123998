export default {
    appResource: 'clientfilemanager',
    tableHeader: [
        {
            label: '#',
            style: {
                width: '40px'
            } 
        },
        {
            label: 'Name',
            style: {
                width: ''
            } 
        },
        {
            label: 'Size',
            style: {
                width: '85px'
            } 
        },
        {
            label: 'Uploaded Date',
            style: {
                width: '175px'
            } 
        },
        {
            label: 'Actions',
            style: {
                width: '200px',
                textAlign: 'center'
            } 
        }
    ],
    controls: {
        select: {
            name: 'select',
            elementType: 'radio',
            elementConfig: { type: 'radio' },
            validation: false,
            styling: ['mx-auto'],
            valid: null,
            touched: null,
            icon: null
        }
    },
    FileToolsButtons: [
        {
            name: 'createFolder_1',
            elementType: 'button',
            elementConfig: { type: 'button', name: 'createFolder_1'},
            label: 'New Folder',
            styling: 'btn btn-primary filetool-btn mr-1',
            icon: 'Open'
        },
        {
            name: 'rename',
            elementType: 'button',
            elementConfig: { type: 'button', name: 'rename_1'},
            label: 'Rename',
            styling: 'btn btn-primary filetool-btn mx-1',
            icon: 'Rename'
        },
        {
            name: 'delete_1',
            elementType: 'button',
            elementConfig: { type: 'button', name: 'delete_1'},
            label: 'Delete',
            styling: 'btn btn-danger filetool-btn mx-1',
            icon: 'Delete'
        }
    ],
    // UploadButton: 
    //     {
    //         name: 'upload',
    //         elementType: 'button-toggle',
    //         elementConfig: [ {type: 'button', name: 'select_file_upload'}, {type: 'button', name: 'upload'} ],
    //         label: ['Upload File', 'Upload'],
    //         styling: ['btn btn-warning filetool-btn ml-1', 'btn btn-success mx-1 filetool-btn flash'],
    //         icon: ['FileUpload', 'Upload'],
    //         toggle: 0
    //     },
        UploadButton: 
        {
            name: 'upload',
            elementType: 'button',
            elementConfig: {type: 'button', name: 'select_file_upload'},
            label: 'Upload File',
            styling: 'btn btn-warning filetool-btn ml-1',
            icon: 'FileUpload'
        },
    
    FolderButtons: [
        {
            name: 'open',
            elementType: 'button',
            elementConfig: { type: 'button', name: 'open'},
            label: 'Open',
            styling: 'btn btn-success ',
            icon: 'Open'
        }
    ],
    DownloadButton: 
        {
            name: 'download',
            elementType: 'button',
            elementConfig: { type: 'button', name: 'download'},
            label: 'Download',
            styling: 'btn btn-primary mx-1 filetool-btn',
            icon: 'Download',
        },
    ViewButton:
        {
            elementType: 'button',
            elementConfig: { type: 'button', name: 'view'},
            label: 'View',
            styling: 'btn btn-secondary mx-1 filetool-btn',
            icon: 'View',
        },
    CloseIconButton:
        {
            elementType: 'button',
            elementConfig: { type: 'button', name: 'close_viewer'},
            styling: 'no-button',
            icon: 'Times',
            iconClass: 'icon-light'
        }, 
    showModal: false,
    ModalConfig: null,
    ModalInputName: null,
    ContentViewer: 
        {   show: false,
            URL: null,
            type: null
        },
    Modals: [
        {   // Please Select a file Modal
            icon: 'Exclamation',
            style: {color : 'orange'},
            title: 'Warning',
            message: 'Please Select a File',
            buttons: [
                {
                    name: 'close',
                    elementType: 'button',
                    elementConfig: { type: 'button', name: 'close'},
                    label: 'Close',
                    styling: 'btn btn-secondary mx-2 filetool-btn',
                },
            ]
        },
        {  // Delete Modal
            title: 'Delete File',
            message: 'Delete this File?',
            buttons: [
                {
                    name: 'close',
                    elementType: 'button',
                    elementConfig: { type: 'button', name: 'close'},
                    label: 'Close',
                    styling: 'btn btn-secondary mx-2 filetool-btn',
                },
                {
                    name: 'delete_2',
                    elementType: 'button',
                    elementConfig: { type: 'button', name: 'delete_2'},
                    label: 'Delete',
                    styling: 'btn btn-danger mx-2 filetool-btn',
                },
            ]
        },
        {  // Rename Modal
            title: 'Rename File',
            message: 'Rename this File?',
            buttons: [
                {
                    name: 'close',
                    elementType: 'button',
                    elementConfig: { type: 'button', name: 'close'},
                    label: 'Close',
                    styling: 'btn btn-secondary mx-2 filetool-btn',
                },
                {
                    name: 'rename_2',
                    elementType: 'button',
                    elementConfig: { type: 'button', name: 'rename_2'},
                    label: 'Rename',
                    styling: 'btn btn-primary mx-2 filetool-btn',
                },
            ]
        },
        {  // Create Modal
            icon: 'Info',
            style: {color : 'blue'},
            title: 'Create Folder',
            message: 'Enter the Folder Name',
            buttons: [
                {
                    name: 'close',
                    elementType: 'button',
                    elementConfig: { type: 'button', name: 'close'},
                    label: 'Close',
                    styling: 'btn btn-secondary mx-2 filetool-btn',
                },
                {
                    name: 'createFolder_2',
                    elementType: 'button',
                    elementConfig: { type: 'button', name: 'createFolder_2'},
                    label: 'Create',
                    styling: 'btn btn-primary mx-2 filetool-btn',
                },
            ]
        },

    ],
    selectedFile: {
        name: null,
        key: null
    },
    uploadFile: {
        name: null,
    },
    fileKey: null,
    downloading: false,
    uploading: false,
    uploadMessage: null,
    pBarWidth: 0
}