// Helper functions
// Process URLs using RegEx
// The resource Name is the Application Page, i.e. Filemanager, ClientManager, etc.
// The currentURL is the name of the CurrentURL, and 
// The USERID is the person currently logged in


//RWC Upload file needs -> filemanager/creighton/
// 
export const UserFolderPOSTPath = (resourceName, currentURL, userID) => {
    const userFolder = '/' + userID;
    const regEx = new RegExp( '/' + resourceName);
    const result = currentURL.replace(regEx, '');
    return resourceName + userFolder + result
}

// This RegEx function removes the AppResource name and extra slashes
// it returns Just the current URL Path that will be the Prepended Path for Key or the 'Key' for S3
// Using this in the Client Manager
export const POSTRequestURL = (resourceName, currentURL) => {
    const regEx = new RegExp(`/${resourceName}/`);
    const result = currentURL.replace(regEx, '');
    return result
}