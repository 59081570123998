// Rambeau Cloud Login In Page
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginState from '../StateConfiguration/LoginState';

import Input from '../../Components/UI/Input';
import Button from '../../Components/UI/Buttons';
import Footer from '../../Components/UI/Footer';
import { InputValidityCheck } from '../../Functions/ValidityCheckers';

import * as actions from '../../Store/Actions/index';

class Login extends Component {
  
    state = LoginState;

    onLoginChangeHandler (event, key) {
        // Copy the state
        const updatedInputs = {...this.state.loginForm};
        //Copy the specific control using the name
        const updatedElement = {...updatedInputs[key]};
        //Update just the value of that control
        updatedElement.value = event.target.value;
        //Copy the updated value back to the controls
        updatedInputs[key] = updatedElement;
        //Update the STATE with by cloning current state, then copy ALL controls
        this.setState({...this.state, loginForm: updatedInputs});
    }

    onConfirmChangeHandler (event, key) {
        // Copy the state
        const updatedInputs = {...this.state.confirmForm};
        //Copy the specific control using the name
        const updatedElement = {...updatedInputs[key]};
        //Update just the value of that control
        updatedElement.value = event.target.value;
        //Check Validity of element
        updatedElement.valid = this.checkValidity(updatedElement.value, updatedElement.validation);
        //Copy the updated value back to the controls
        updatedInputs[key] = updatedElement;

        //Check the Form Validity, Create a 'Map' of valid and touched
        let formElementValidity = true;

        let validMap = new Map();
        // Create a Hash Map
        Object.entries(this.state.confirmForm).forEach(
            ([key, value]) => {
                validMap.set(key, value.valid );
            }
        );
        //Update the Map with the current element valid state
        validMap.set(key,updatedElement.valid);
        if (event.target.name === 'confirmPassword') {
            if (updatedElement.value !== this.state.confirmForm.newPassword.value) {
                validMap.set(event.target.name, false);
            }
            else validMap.set(event.target.name, true);
        }
        // Update the input controls for the state
        validMap.forEach( (value, key) => {
            updatedInputs[key].valid = value;
        });

        // Update the form Validity
        validMap.forEach( (value, key) => {
            formElementValidity = updatedInputs[key].valid && formElementValidity;
        });
        //Update the STATE with by cloning current state, then copy ALL controls
        this.setState({...this.state, confirmForm: updatedInputs, formValid: formElementValidity});
    }

    onBlurHandler (event) {
        const key = event.currentTarget.name;
        const value = event.currentTarget.value;
        //Copy the state
        const updatedInputs = {...this.state.confirmForm};
        //Copy the specific control using the name
        const updatedElement = {...updatedInputs[key]};
        // Check Validity
        updatedElement.valid = InputValidityCheck(value, updatedElement.validation);
        // Input is NOW touched
        updatedElement.touched = true;
        //Copy the updated value back to the controls
        updatedInputs[key] = updatedElement;
        let message;  // Form input related message

        let formElementValidity = true;

        let validMap = new Map();
        // Create a Hash Map to check just the form validity
        Object.entries(this.state.confirmForm).forEach(
            ([key, value]) => {
                validMap.set(key, (value.valid && value.touched) );
            }
        );
        //Update the Map with the current element touched state
        validMap.set(key,updatedElement.touched);

        //Check if Passwords Match for form Validity
        validMap.forEach( (value, key) => {
            if ( (key === 'newPassword') || (key === 'confirmPassword') ) {
                if ( validMap.get('newPassword') && validMap.get('confirmPassword') ){
                    if ( this.state.confirmForm.newPassword.value !== this.state.confirmForm.confirmPassword.value) {
                        message = `The passwords don't match`;
                        formElementValidity = false;
                    } 
                    else {
                        message = null;
                        formElementValidity = true;
                    }
                }
            }
        });
        // Validate the form
        validMap.forEach( (value, key) => {
            formElementValidity = value && formElementValidity;
        })

        //Update the STATE with by cloning current state, then copy ALL controls
        this.setState({...this.state, confirmForm: updatedInputs, formValid: formElementValidity, message: message});
    }

    // Check the input validity based on state-defined rules
    checkValidity = (value, rules) => InputValidityCheck(value, rules);

    SigninHandler = (event) => {
        //Block Default
        event.preventDefault();
        // Dispatch the Cognito Sign in component
        this.props.onAuth(this.state.loginForm.username.value, this.state.loginForm.password.value, this.state.confirmForm.newPassword.value);
    }

    componentDidMount() {
        if ( !this.props.authenticated && !this.props.loading && !this.props.initialized ) {
            this.props.getCurrentUser();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let initComplete = false;
        let loginFormStateChange = false;
        let authenticated = false;
        let authenticateFail = false;
        let passwordReset = false;
        
        // Completed Initialization, meaning we have a valid USERID...but we are still in Loading state
        initComplete = (nextProps.initialized && !nextProps.loading);
        // StateChange
        loginFormStateChange = (nextState.loginForm !== this.state.loginForm);
        // The user is authenticated
        authenticated = nextProps.authenticated;
        // The user needs to reset password
        passwordReset = nextProps.password_reset;
        // Bad login
        authenticateFail = this.props.error !== nextProps.error;

        if ( true ) { // Initialization is complete (valid User) or (failure),
            if (initComplete) return true
            else if (loginFormStateChange) return true
            else if (authenticated) return true
            else if (passwordReset) return true
            else if (authenticateFail) return true
            else return false
        }
        else   
            return false
    }

    componentDidUpdate(prevProps, prevState) {
        // Turn the redirect on if we just became authenticate
        if (!prevState.redirect && !this.state.redirect && this.props.authenticated) {
            this.setState({...this.state, redirect: true, formValid: false})
        }
        if (!prevProps.password_reset && this.props.password_reset) {
            this.setState({formValid: false})
        }
    }
    
    render () {
        //Redirect if Authentication Success
        let authenticateRedirect = null;
        if (this.state.redirect) {
            authenticateRedirect = <Redirect to="/home" />
        }
        //Error Message Handling
        let errorMessage = null;
        if (this.props.error) {
            errorMessage = <p>{this.props.error}</p>
        }
        //Login Form
        let loginFormElementsArray = [];
        for (let input in this.state.loginForm) {
            loginFormElementsArray.push({key: input, config: this.state.loginForm[input]});
        }
        //Build Form
        const loginForm = loginFormElementsArray.map( formElement => {
            return ( 
                <Input 
                    key={formElement.key} 
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    styling={formElement.config.styling}
                    value={formElement.config.value}
                    validation={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={ (event) => this.onLoginChangeHandler(event, formElement.key) }
                />
            )
        });

        let newPassForm = null;
        let passwordRules = null;
        if (this.props.password_reset) {
            // Form is now invalid            
            //Password Reset Form
            let newPassFormElementsArray = [];
            for (let input in this.state.confirmForm) {
                newPassFormElementsArray.push({key: input, config: this.state.confirmForm[input]});
            }
            newPassForm = newPassFormElementsArray.map( formElement => {
                return (
                    <Input 
                        key={formElement.key} 
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        styling={formElement.config.styling}
                        value={formElement.config.value}
                        valid={formElement.config.valid}
                        validation={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={ (event) => this.onConfirmChangeHandler(event, formElement.key) }
                        blur={ (event) => this.onBlurHandler(event) }
                    />
                )
            });
            passwordRules = (
                <p className="password-rules">
                    <span className="password-rules">Password Rules:</span>
                    <br/>
                    Minimum of 8 characters.
                    <br/>
                    One uppercase letter.
                    <br/>
                    One number.
                </p>
            )
        }
        //JSX
        return (
            <div className="login-wrapper">
                <div className="login-box col">
                    {authenticateRedirect}
                    <div className="card text-center card-form login-card">
                        <div className="card-body">
                            <div className="rambeau-cloud-logo" />
                            <div className="card-title">
                                <strong>Login</strong>
                            </div>
                            <div className="login-message">
                                {errorMessage}
                            </div>
                            <form className="mt-3" onSubmit={this.SigninHandler}>
                                {loginForm}
                                {newPassForm}
                                {passwordRules}
                                <br></br>
                                <Button
                                    elementType={this.state.buttons.elementType}
                                    elementConfig={this.state.buttons.elementConfig}
                                    label={this.state.buttons.label}
                                    styling={this.state.buttons.styling}
                                    icon={this.state.buttons.icon}
                                    disabled={!this.state.formValid}>
                                </Button>
                            </form>
                            <br></br>
                            <p><Link to="forgotpassword">Forgot Password?</Link></p>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

// This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        initialized: state.initialization.initialized,
        loading: state.loading.load,
        error: state.authenticate.error,
        authenticated: state.authenticate.idToken !== null,
        password_reset: state.authenticate.password_reset
    }
}

// This maps Props within Container to Redux State
// Output of component, call functions in the Store->Actions->Function
const mapDispatchtoProps = (dispatch) => {
    return {
    // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
        onAuth: (username, password, newPassword) => dispatch(actions.authenticate(username, password, newPassword) ),
        getCurrentUser: () => dispatch(actions.getCurrentUser() ),
    }
}

export default (connect(mapStatetoProps, mapDispatchtoProps))(Login);