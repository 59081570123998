import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../Store/Actions/index';
import RambeauCloudLogo from '../../Assets/Logo/rambeau_cloud.svg'



class Home extends Component {

    
    render() {
        return (
            <div className="container-fluid">
                <div className="row no-gutters justify-content-center px-3 pt-5">
                    <div className="col-lg-6 col-md-8">
                        <div className="card" >
                            <img className="card-img-top rambeau-cloud-logo-home" src={RambeauCloudLogo} alt=""></img>
                                <div className="card-body">
                                    <h5 className="card-text welcome">Welcome to Rambeau Cloud</h5>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


//This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        initialized: state.initialization.initialized,
        loading: state.loading.load,
        userID: state.authenticate.userID,
        authenticated: state.authenticate.idToken !== null,
    }
}

// This maps Props within Container to Redux State
// Output of component, call functions in the Store->Actions->Function
const mapDispatchtoProps = (dispatch) => {
    return {
    // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
        onLoad: (loading) => dispatch(actions.Loading(loading))
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Home);