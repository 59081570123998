export default {
    tableHeader: [
        {
            label: 'Client',
            style: {
                width: ''
            } 
        },
        {
            label: 'Status',
            style: {
                width: '200px',
                textAlign: 'center'
            } 
        },
        {
            label: 'Actions',
            style: {
                width: '200px',
                textAlign: 'center'
            } 
        }
    ],
    NewClientButton:
        {
            name: 'newClient',
            elementType: 'button',
            elementConfig: { type: 'button', name: 'newClient'},
            label: 'New Client',
            styling: 'btn btn-primary mx-2 filetool-btn',
            
        },
    TableButtons: [
        {
            name: 'Select',
            icon: 'List',
            elementType: 'button',
            elementConfig: { type: 'button', name: 'Select'},
            label: 'Select',
            styling: 'btn btn-outline-warning mx-2 filetool-btn'
        },
    ],
    selectedUser: {
        firstName: null,
        lastName: null,
        email: null,
    },
    userCard: false, // User  Card Details
    editForm: false, // Change Card Detail to Editable Form
    formInputs : {
        name: {
            label: 'first name:',
            elementType: 'input-noIcon',
            elementConfig: { type: 'text', placeholder: 'First Name'}, 
            value: '',
            styling: ['form-input'],
            validation: { required: true},
            valid: false,
            touched: false
        },
        family_name: {
            label: 'last name:',
            elementType: 'input-noIcon',
            elementConfig: { type: 'text', placeholder: 'Last Name' }, 
            value: '',
            styling: ['form-input'],
            validation: { required: true},
            valid: false,
            touched: false
        },
        email: { 
            label: 'email:',
            elementType: 'input-noIcon', 
            elementConfig: { type: 'email', placeholder: 'Email' } , 
            value: '',
            styling: ['form-input'],
            validation: { required: true},
            valid: false,
            touched: false
        }
    },
    userCardButtons: [
        {
            name: 'Edit',
            elementType: 'button-toggle',
            elementConfig: [ { type: 'button', name: 'Edit'} , { type: 'button', name: 'Save'} ],
            label: ['Edit', 'Save'],
            styling:  ['btn btn-outline-success mx-1 filetool-btn', 'btn btn-outline-danger mx-1 filetool-btn'],
            icon: ['Edit', 'Save'],
            toggle: 0
        },
        {
            name: 'Enable',
            elementType: 'button-toggle',
            elementConfig: [ { type: 'button', name: 'Enable'}, { type: 'button', name: 'Enable'} ],
            label: ['Enable', 'Disable'],
            styling: ['btn btn-success mx-1 filetool-btn', 'btn btn-danger mx-1 filetool-btn'],
            icon: ['ThumbsUp', 'ThumbsDown'],
            toggle: 0
        },
        {
            name: 'resetPass',
            icon: 'Secret',
            elementType: 'button',
            elementConfig: { type: 'button', name: 'resetPass'},
            label: 'Reset Password',
            styling: 'btn btn-outline-primary mx-1 filetool-btn'
        },
        {
            name: 'Close',
            icon: 'Times',
            elementType: 'button',
            elementConfig: { type: 'button', name: 'Close'},
            label: 'Close',
            styling: 'btn btn-outline-primary mx-1 filetool-btn'
        }
    ]
}