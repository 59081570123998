export default {
    verifyForm: {
        username: { 
            elementType: 'input', 
            elementConfig: { type: 'text', placeholder: 'Username', icon: 'User' } , 
            value: '',
            styling: ['form-control login-control'],
            validation: { required: 'true'},
            valid: false,
            touched: false
        },
        code: { 
            elementType: 'input', 
            elementConfig: { type: 'text', placeholder: 'Verification Code', icon: 'Key' } , 
            value: '',
            styling: ['form-control login-control'],
            validation: { required: 'true'},
            valid: false,
            touched: false
        },
    }, 
    buttons: {
        name: 'Verify',
        elementType: 'button',
        elementConfig: { type: 'submit', name: 'Login'},
        label: 'Verify',
        styling:  'btn btn-success btn-block text-uppercase',
        icon: 'SignIn'
    },
}