// Rambeau Cloud Verify In Page
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import VerifyState from '../StateConfiguration/VerifyState';

import Input from '../../Components/UI/Input';
import Button from '../../Components/UI/Buttons';
import Footer from '../../Components/UI/Footer';

import * as actions from '../../Store/Actions/index';

class Verify extends Component {

    state = VerifyState;

    onChangeHandler (event, inputKey) {
        //Copy the state
        const updatedInputs = {...this.state.verifyForm};
        //Copy the specific control using the id
        const updatedElement = {...updatedInputs[inputKey]};
        //Update just the value of that control
        updatedElement.value = event.target.value;
        //Copy the updated value back to the controls
        updatedInputs[inputKey] = updatedElement;
        this.setState({...this.state, verifyForm: updatedInputs});
    }

    VerifyHandler = (event) => {
        //Block Default
        event.preventDefault();
        // Dispatch the Cognito Sign in component
        this.props.verify(this.state.verifyForm.username.value, this.state.verifyForm.code.value);
    }

    componentDidMount() {
        if ( !this.props.authenticated && !this.props.loading && !this.props.initialized ) {
            this.props.getCurrentUser();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        let initComplete = false;
        let verification = true;
        let inputStateChange = false;
        let authenticated = false;
        
        // Completed Initialization, meaning we have a valid USERID...but we are still in Loading state
        initComplete = (nextProps.initialized && !nextProps.loading);
        // StateChange
        inputStateChange = (nextState.verifyForm !== this.state.verifyForm)
        // The user is authenticated
        authenticated = nextProps.authenticated;
        // The user is NOT verfied
        verification = nextProps.verification === false;
        // The user is NOT verfied
        verification = nextProps.verification === true;

        if ( initComplete ) { // Initialization is complete (valid User) or (failure),
            if (inputStateChange)
                return true
            else if (authenticated)
                return true
            else if (verification)
                return true
            else
                return false
        }
        else
            return false
    }
    componentDidUpdate(prevProps, prevState) {
        // Browser Buttons - Props changed because browser buttons (Back/Forward) were pressed
        if (this.props.location.pathname !== prevProps.location.pathname && !this.props.loading ) {
            console.log('hit')
            
        }
        if (this.props.verification === true) {
            this.props.history.push('/login');
        }

    }

    render () {
        const formElementsArray = [];
        for (let input in this.state.verifyForm) {
            formElementsArray.push( {key: input, config: this.state.verifyForm[input]} )
        }

        const form = formElementsArray.map( formElement => {
            return (
                <Input 
                    key={formElement.key} 
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    styling={formElement.config.styling}
                    value={formElement.config.value} 
                    validation={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={ (event) => this.onChangeHandler(event, formElement.key) } 
                    />
                )
            }
        )

        //Redirect After Verify Success
        let verifyRedirect = null;
        if (this.props.verification && this.props.authenticated) {
            verifyRedirect = <Redirect to ="/login" />
        }

        return (
            <div className="login-box col">
                <div className="card text-center card-form login-card">
                    <div className="card-body">
                        <div className="rambeau-cloud-logo" />
                        <div className="card-title">
                            <strong>Verify</strong>
                        </div>
                        {verifyRedirect}
                        <form className="mt-3" onSubmit={this.VerifyHandler}>
                            {form}
                            <br></br>
                            <Button
                                elementType={this.state.buttons.elementType}
                                elementConfig={this.state.buttons.elementConfig}
                                label={this.state.buttons.label}
                                styling={this.state.buttons.styling}
                                icon={this.state.buttons.icon}>
                            </Button>
                        </form>
                        <br></br>
                        <p><Link to="login">Back to Login?</Link></p>
                        <p><Link to="forgotpassword">Forgot Password?</Link></p>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}


//This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        initialized: state.initialization.initialized,
        loading: state.loading.load,
        error: state.authenticate.error,
        authenticated: state.authenticate.idToken !== null,
        verification: state.authenticate.email_verified
    }
}

//This maps Props within Container to Redux State
// Output of component, call functions in the Store->Actions->Function
const mapDispatchtoProps = (dispatch) => {
    return {
        // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
        verify: (username, code) => dispatch(actions.verify(username, code)),
        getCurrentUser: () => dispatch(actions.getCurrentUser() )
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Verify);