import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
// Redux Actions
import * as actions from '../../Store/Actions/index';
// Components
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs';
import FileTable from '../../Components/Tables/FileTable';
import FileTools from './FileTools';
import Modal from '../../Components/UI/Modal';
import ContentViewer from '../../Components/UI/ContentViewer';
// Utilities
//import AxiosDownload from '../../Components/Utils/FileOperations';
import DateFormat from '../../Functions/DateFormat';
import ByteFormat from '../../Functions/ByteFormat';
import { UserFolderPOSTPath, POSTRequestURL } from '../../Functions/RegexURL';
// State Configuration
import ClientFileManagerState from '../StateConfiguration/ClientFileManagerState';
//Fa Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons'

class ClientFileManager extends Component {

    state = ClientFileManagerState

    componentDidMount() {
        // RWC - Only check Authentication in the Side Nav Toolbar
        // Mounted, Authenticated and NOT Loading, time to retrieve Files/Folders
        if ( this.props.authenticated && !this.props.loading) {
            this.AxiosGETHandler('');
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // Initialize the Booleans
        let loadingTransition = false;
        let browserButtonPressed = false;
        let uploadFile = false;
        let selectFile = false;
        let showModal = false;
        let buttonToggle = false;
        let downloadFile = false;
        let progressBarUpdate = false;
        let contentView = false;

        // Booleans
        // Loading transition has occurred
        loadingTransition = (nextProps.loading && !this.props.loading) || (!nextProps.loading && this.props.loading);
        // Browser Button press detected - skip if null
        if (this.props.location !== undefined) {
            browserButtonPressed = (this.props.location.pathname !== nextProps.location.pathname)
        }
        // Toolbar Change
        buttonToggle = nextState.FileToolsButtons !== this.state.FileToolsButtons;
        // Choose a file
        uploadFile = nextState.uploadFile.name !== this.state.uploadFile.name;
        selectFile = nextState.selectedFile.name !== this.state.selectedFile.name;
        // Download a file 
        downloadFile = nextState.downloading !== this.state.downloading;
        progressBarUpdate = nextState.pBarWidth !== this.state.pBarWidth;
        showModal = nextState.showModal !== this.state.showModal;
        contentView = nextState.ContentViewer.show !== this.state.ContentViewer.show;

        // Condition to determine if Updating is needed
        if ( loadingTransition ) return true    
        else if ( uploadFile ) { // File to upload, The state has changed
            return true
        }
        else if ( selectFile ) // File to select, The state has changed
            return true
        else if ( downloadFile || progressBarUpdate )
            return true
        else if ( buttonToggle ) // File to select, The state has changed
            return true
        else if ( showModal ) return true
        else if ( browserButtonPressed ) { // Browser Buttons have been selected 
            this.props.onLoad(true); // We now need to load
            return true
        } else if ( contentView ) return true
        else 
            return false
    }

    componentDidUpdate(prevProps, prevState) {
        // ONLY call AWS if we are authenticated
        if (this.props.authenticated) {
            // Axios call conditions
            // 1. No Contents - we just refreshed -- Initialized and Authenticated
            if (prevProps.initialized === false && this.props.initialized === true && !this.props.loading) {
                this.AxiosGETHandler('');
            }
            // 2. Browser Buttons - Props changed because browser buttons (Back/Forward) were pressed
            if (this.props.location.pathname !== prevProps.location.pathname && !this.props.loading ) {
                this.AxiosGETHandler('');
            }
            // 3. State chnaged after File Upload
            if ((prevState.uploadFile.name !== null) && (this.state.uploadFile.name === null)) {
                this.AxiosGETHandler('');
            }
        }
    }

    ButtonHandler(event, key, label) {  
        if (event.currentTarget.type === 'button') {
            switch(event.currentTarget.name) {
                case('open'):
                    this.props.history.push( this.props.location.pathname + '/' + label);
                    break;
                case('download'):
                    this.setState({fileKey: key}) ;
                    this.DownloadHandler(label, key);
                    break;
                case('view'):
                    this.setState({fileKey: key}, () => this.ViewHandler(key) ) ;
                    break;
                case('delete_1'):  // Launches Modal to caution user
                    if ( this.state.selectedFile.name === null ) {
                        this.setState({ModalConfig: this.state.Modals[0]})
                    } else {
                        this.setState({ModalConfig: this.state.Modals[1]})
                    }
                    this.setState({ showModal: true} )
                    break;
                case('delete_2'):
                    //this.props.onLoad(true);
                    this.DeleteHandler(this.state.selectedFile);
                    this.setState({ showModal: false });
                    break;
                case('rename_1'):
                    if ( this.state.selectedFile.name === null ) {
                        this.setState({ModalConfig: this.state.Modals[0]})
                    } else {
                        this.setState({ModalConfig: this.state.Modals[2]})
                    }
                    this.setState({ showModal: true} )
                    break;
                case('rename_2'):
                    this.props.onLoad(true);
                    this.RenameHandler(event);
                    this.setState({ showModal: false });
                    break;
                case('createFolder_1'):
                    this.setState({ showModal: true, ModalConfig: this.state.Modals[3]} )
                    break;
                case('createFolder_2'):
                    this.CreateFolderHandler(event);
                    this.setState({ showModal: false })
                    break;
                case('move'):
                    console.log('move');
                    console.log(this.state.selectedFile);
                    break;
                case('select_file_upload'):
                    // Using a React Reference to point to the Input from a Button
                    this.fileInput.click();
                    break;
                case('close'):
                    // Clear the file link from the input
                    this.setState( {showModal: false} );
                    break;
                case('close_viewer'):
                    const show = {show: false};
                    const ContentView = Object.assign(show);
                    this.setState( {ContentViewer: ContentView} );
                    break;
                default:
                    break;
            }

        } else if (event.target.type === 'radio') {
            if(key === this.state.selectedFile.key) {
                this.setState({...this.state,
                    selectedFile: {
                        name: null,
                        key: null
                    }
                });
            } else {
                this.setState({...this.state,
                    selectedFile: {
                        name: label,
                        key: key
                    }
                });
            }
        }
    }

    AxiosGETHandler(folder) {
        //Filter Client File Manager out of the Path
        const currentPath = this.props.location.pathname.split('/').filter(Boolean).filter( (path) => path !== 'clientfilemanager' ).join('/');

        let basePath;
        if (currentPath !== '')
            basePath = `/${currentPath}`;
        else basePath = currentPath;

        let folderPath;
        if (folder !== '')
            folderPath = `/${folder}`;
        else folderPath = '';

        const newPath = (`${this.state.appResource}/users${basePath}${folderPath}?action=list`)
        console.log('Axios Called on ->  ', newPath);

        this.props.onLoad(true); // Start the Load & Spinner
        axios.get(newPath, {headers: {Authorization: this.props.idToken}}).then( (response) => {
            // Format a Readable Date
            if (response.data.Contents)
                response.data.Contents.forEach(element => {
                    element.UploadDate = DateFormat(new Date(element.LastModified)) 
                    element.ByteSize = ByteFormat(element.Size)
                });
            //console.log(response.data)
            this.setState( {...this.state, 
                    Contents: response.data.Contents, 
                    CommonPrefixes: response.data.CommonPrefixes,
                    selectedFile: {
                        name: null,
                        key: null
                }}, //Callback
                   () => {
                       this.props.onLoad(false) 
                   });  
            
        })
        .catch( (error) => {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log('401 Error in Client File Manager', error.message);
                console.log('fail in Ax Get H');
                this.props.onLoad(false); 
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
                this.props.onLoad(false);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                console.log('If 401 then auth failure');
                console.log('Why not erase the UserID???');
                this.props.onLoad(false); 
            }
        })
    }


    onChangeHandler = (event) => {
        //Update just the value of that control
        const filename = event.target.value;
        this.setState({ModalInputName: filename});
    }

    fileUploadSelectHandler = (event) => {
        console.log(event);
        // Catches a user cancel on the file upload
        if (event.target.files[0] === undefined)
            this.setState({...this.state, uploadFile: {name: null}})
        else  // Upload file immediate after selecting file
            this.setState({...this.state, uploadFile: event.target.files[0] }, () => this.fileUploadHandler());
    }

    // Browser/File Upload Method
    fileUploadHandler = () => {
        //Create the key
        const path = POSTRequestURL(this.state.appResource, this.props.location.pathname)
        const key =  `${path}/${this.state.uploadFile.name}`;
        // Request an S3 bucket link, create a form with the Data
        let formData = new FormData();
        formData.append('UPLOAD_URL' , JSON.stringify({key: key, ContentType: this.state.uploadFile.type}));

        // Post to AWS, Request the URL
        axios.post(this.props.location.pathname, formData, { headers: { Authorization: this.props.idToken} } ).then( response => {
            // The new S3 URL
            axios({
                url: response.data.url,
                method: 'PUT',
                data: this.state.uploadFile,
                headers: {'Content-Type': this.state.uploadFile.type },
                onUploadProgress: (progressEvent) => {
                    const progress = ( Math.round(progressEvent.loaded / progressEvent.total * 100) );
                    this.setState({uploading: true, pBarWidth: progress} );
                } 
            }).then( (response) => {
                this.setState({ uploadFile: {name: null,key: null}});     
            }).then( () => { setTimeout( () => this.setState({fileKey: null, uploading: false, pBarWidth: 0}), 1000)});
        });
    }
    // Create a folder
    CreateFolderHandler = (event) => {
        //Block Default on form submint
        event.preventDefault();
        // Get the current Path that will be prepended to the key
        const path = POSTRequestURL(this.state.appResource, this.props.location.pathname);
        let formData = new FormData();
        //S3 folders need the format = name/
        formData.append('CREATE_FOLDER' , JSON.stringify({key: `${path}/${this.state.ModalInputName}/`} ));
        // This path is made of the AppResource name and the UserID
        // Only posting to the AWS Proxy UserName share
        axios.post(this.props.location.pathname, formData, { headers: { Authorization: this.props.idToken} })
            .then( response => {
                this.AxiosGETHandler('');
            });
    }
    // Delete a File/Folder
    DeleteHandler = (selectedFile) => {
        let formData = new FormData();
        formData.append('DELETE' , JSON.stringify({key: selectedFile.key}));
        // For Delete, just POST the form straight to API Gateway
        axios.post(this.props.location.pathname, formData, { headers: { Authorization: this.props.idToken} })
            .then( response => {
                this.AxiosGETHandler('');
            });
    }

    // Rename a File
    RenameHandler = (event) => {
        //Block Default on form submint
        event.preventDefault();
        let formData = new FormData();
        formData.append('RENAME' , JSON.stringify({key: this.state.selectedFile.key, newName: this.state.ModalInputName}));
        // This path is made of the AppResource name and the UserID
        // Only posting to the AWS Proxy UserName share
        axios.post(this.props.location.pathname, formData, { headers: { Authorization: this.props.idToken} })
            .then( response => {
                this.AxiosGETHandler('');
            });
    }

    // Browser/File Upload Method
    DownloadHandler = (file, key) => {
        if (key) {
            //Create the path
            const path = UserFolderPOSTPath(this.state.appResource, this.props.location.pathname, this.props.userID);
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            // Request an S3 bucket link
            let formData = new FormData();
            formData.append('DOWNLOAD_URL' , JSON.stringify({key: key}));
            // Post to AWS, Request the URL
            axios.post(path, formData, { headers: { Authorization: this.props.idToken} } ).then( response => {
                axios({
                    url: response.data.url,
                    method: 'GET',
                    responseType: 'blob', // important
                    // `onDownloadProgress` allows handling of progress events for downloads
                    cancelToken: source.token,
                    onDownloadProgress:  (progressEvent) => {
                        const progress = ( Math.round(progressEvent.loaded / progressEvent.total * 100) );
                        this.setState({downloading: true, pBarWidth: progress} );
                    },
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', file);
                        document.body.appendChild(link);
                        link.click();
                    }).catch( (thrown) => {
                        if (axios.isCancel(thrown)) {
                          console.log('Request canceled', thrown.message);
                        } else {
                          // handle error
                        }
                    }).then( () => { setTimeout( () => this.setState({fileKey: null, downloading: false, pBarWidth: 0}), 1000)});
            });
        }
    }

    ViewHandler = (key) => {
        if (key) {
            //Create the path
            const path = UserFolderPOSTPath(this.state.appResource, this.props.location.pathname, this.props.userID);
            // Request an S3 bucket link
            let formData = new FormData();
            formData.append('DOWNLOAD_URL' , JSON.stringify({key: key}));
            // Post to AWS, Request the URL
            axios.post(path, formData, { headers: { Authorization: this.props.idToken} } )
                 //.then( response => console.log(response.data.url, response));
                 .then( response => {
                    const show = {show: true};
                    const URL = {URL: response.data.url};
                    const contentViewer = Object.assign(show, URL);
                    this.setState( {ContentViewer: contentViewer} );
                 });
        }
    }

    render () {
        let contentViewer;
        if (this.state.ContentViewer.show) {
            contentViewer = <ContentViewer 
                                show = {this.state.ContentViewer.show}
                                URL = {this.state.ContentViewer.URL}
                                button = {this.state.CloseIconButton}
                                clicked = { (event) => this.ButtonHandler(event) }
                                />
        }
        else contentViewer = null;

        
        return (
            <div className="main-content container-fluid">
                {contentViewer}
                <Modal  
                    ModalConfig={this.state.ModalConfig} 
                        ShowModal={this.state.showModal}
                        SelectedFile={this.state.selectedFile}
                        buttonHandler={ (event, key, label) => this.ButtonHandler(event, key, label) }
                        changeHandler={ (event) => this.onChangeHandler(event)  }
                />

                <div className="row no-gutters p-3">
                    <div className="col-lg-6">
                        <div className="page-header-title">
                            <span className="icon">
                            <FontAwesomeIcon icon={faBox} size="2x"/>
                            </span>
                            <div className="page-title d-inline">
                                <h5>Client File Manager</h5>
                                <span className="page-description">Manange Client content right here</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <Breadcrumbs path={this.props.location.pathname}/>
                    </div>
                </div>
                <input  id="file_input"
                        type="file" 
                        style={{display: 'none'}} 
                        onClick={event => console.log(event)}
                        onChange={(event) => this.fileUploadSelectHandler(event)}
                        ref={fileInput => this.fileInput = fileInput}>
                </input>

                <div className="row no-gutters p-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="container-fluid">
                                    <FileTools 
                                        Buttons={this.state.FileToolsButtons}
                                        buttonHandler={ (event, key, label) => this.ButtonHandler(event, key, label) }
                                        UploadButton={this.state.UploadButton}
                                        uploading={this.state.uploading}
                                        pBarWidth={this.state.pBarWidth}
                                        disabled={this.state.uploading || this.state.downloading}
                                    />
                                    <FileTable  
                                        Header={this.state.tableHeader} 
                                        Folders={this.state.CommonPrefixes} 
                                        Files={this.state.Contents}
                                        Controls={this.state.controls}
                                        viewButton={this.state.ViewButton}
                                        downloadButton={this.state.DownloadButton}
                                        fileKey={this.state.fileKey}
                                        downloading={this.state.downloading}
                                        pBarWidth={this.state.pBarWidth}
                                        folderButtons={this.state.FolderButtons}
                                        loading={this.props.loading}
                                        Checked={this.state.selectedFile}
                                        buttonHandler={ (event, key, label) => this.ButtonHandler(event, key, label) }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        initialized: state.initialization.initialized,
        loading: state.loading.load,
        idToken: state.authenticate.idToken,
        userID: state.authenticate.userID,
        authenticated: state.authenticate.idToken !== null,
    }
}

// This maps Props within Container to Redux State
// Output of component, call functions in the Store->Actions->Function
const mapDispatchtoProps = (dispatch) => {
    return {
    // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
        getCurrentUser: () => dispatch(actions.getCurrentUser() ),
        onLoad: (loading) => dispatch(actions.Loading(loading))
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(ClientFileManager)