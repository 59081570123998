import React from 'react';
// UI Components
import Button from '../UI/Buttons';
import Input from '../UI/Input';
// Utilities
// Demo Image
import flower from '../../Assets/Extra/flower.jpg'

const UserCard = (props) => {
    // Edit User Buttons
    let buttons = null;
    if (props.Buttons) {
        buttons = props.Buttons.map( (button) => {

            return <Button 
                        key={button.name}
                        elementType={button.elementType}
                        elementConfig={button.elementConfig}
                        label={button.label}
                        styling={button.styling}
                        icon={button.icon}
                        toggle={button.toggle}
                        clicked={(event) => props.buttonHandler(event, button.name)}
                    />
        })
    }

    // 'Enabled' Label
    let enabledStatus;
    if (props.User.Enabled === undefined) {
        enabledStatus =  <span></span>
    } else if (props.User.Enabled === true) {
        enabledStatus =  <span className="badge badge-success">Active</span>
    } else {
        enabledStatus =  <span className="badge badge-danger">Inactive</span>
    }

    // Display the Users Information
    let card = (
        <div className="card-body profile-view">
            <div className="row">
                <div className="col-md-3 d-flex align-items-center profile-title">
                    <img src={flower} alt="" className="img-circle img-responsive" />
                    <h3 className="profile-item">{props.User.name} {props.User.family_name}</h3>
                </div>
                <div className="col-md-9 d-flex justify-content-start profile-title">
                    <div className="row form-group">
                        <label className="col-md-5 col-xs-5 form-label">first name:</label>
                        <div className="input-group contact-info col-md-7">
                            {props.User.name}
                        </div>
                    </div>
                    <div className="row form-group">
                        <label className="col-md-5 col-xs-5 form-label">last name:</label>
                        <div className="input-group contact-info col-md-7">
                            {props.User.family_name}
                        </div>
                    </div>
                    <div className="row form-group">
                        <label className="col-md-5 col-xs-5 form-label">email:</label>
                        <div className="input-group contact-info col-md-7">
                            {props.User.email}
                        </div>
                    </div> 
                    <div className="row form-group">
                        <label className="col-md-5 col-xs-5 form-label">username:</label>
                        <div className="input-group contact-info col-md-7">
                            {props.User.Username}
                        </div>
                    </div> 
                    <div className="row form-group">
                        <label className="col-md-5 col-xs-5 form-label">Status:</label>
                        <div className="input-group col-md-7">
                            {enabledStatus}
                        </div>
                    </div>   
                    <div className="row form-group">
                        <label className="col-md-5 col-xs-5 form-label">Email Verified:</label>
                        <div className="input-group contact-info col-md-7">
                            {props.User.email_verified}
                        </div>
                    </div>       
                </div>
            </div>
        </div>
    )

    // Form for Editing the User
    if (props.Edit) {
        // Form Inputs
        // Create an Array of the input Controls
        let inputArray = [];
        for (let input in props.Inputs) {
            inputArray.push({key: input, Input: props.Inputs[input]});  // push the WHOLE object
        }
        
        const form = inputArray.map( input => {
            return (
                <div key={input.key} className="row form-group">
                    <label className="col-md-5 col-xs-5 form-label">{input.Input.label}</label>
                    <Input
                        elementType={input.Input.elementType}
                        elementConfig={input.Input.elementConfig}
                        label={input.Input.label}
                        value={input.Input.value}
                        styling={input.Input.styling}
                        changed={ (event) => props.onChangeHandler(event, input.key) }
                    />
                </div>
            )
        })
        
        card = (
            <div className="card-body profile-view">
                <div className="row">
                    <div className="col-md-3 d-flex align-items-center profile-title">
                        <img src={flower} alt="" className="img-circle img-responsive" />
                        <h3 className="profile-item">{props.User.name} {props.User.family_name}</h3>
                    </div>
                    
                    <form className="col-lg-9 d-flex align-items-start profile-title">
                        {form}
                        <div className="row form-group">
                            <label className="col-md-5 col-xs-5 form-label">username:</label>
                            <div className="input-group col-md-7 line-height-base">
                                {props.User.Username}
                            </div>
                        </div>
                        <div className="row form-group">
                            <label className="col-md-5 col-xs-5 form-label">status:</label>
                            <div className="input-group col-md-7">
                                {enabledStatus}
                            </div>
                        </div>
                    </form>


                </div>
            </div>
        )
    }

    return (
        <div className="col-lg-12 profile-details">
            {card}
            <div className="card-footer bg-transparent border-success d-flex justify-content-center">
                {buttons}
            </div> 
        </div> 
    )
}

export default UserCard;