// Redux ACTION FILE
// Initialization Process
import * as actionTypes from './actionTypes';

export const Initializaton = (init) => {
    if (init)
        return {
            type: actionTypes.INITIALIZED,
            initialized: true
        }
    else 
        return {
            type: actionTypes.NOT_INITIALIZED,
            initialized: false
        }
}

export default Initializaton;