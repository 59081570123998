import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons'

const breadcrumbs = (props) => {
    const Path = props.path.split('/').filter(Boolean);

    const Pages = Path.map( (page, index) => {
        return <li key={index} className="breadcrumb-item">{page}</li>
    })
 
    return (
        <nav className="breadcrumb-wrapper">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><FontAwesomeIcon icon={faHome} /></li>
                {Pages}
            </ol>
        </nav>
    )
};


export default breadcrumbs;