// Reducer File for Authentication
import { INITIALIZED, NOT_INITIALIZED } from "../Actions/actionTypes";

// Initial State required
const initialState = {
    initialized: false,
}

export const initialized = (state, action) => {

    return {...state, 
        initialized: action.initialized
    }
}

export const notInitialized = (state, action) => {

    return {...state, 
        initialized: action.initialized
    }
}


// Simple Reducer Switch Statement
const reducer = (state = initialState, action) => {

    switch ( action.type ) {
        case INITIALIZED: return initialized (state, action);
        case NOT_INITIALIZED: return notInitialized (state, action);

        default: return state
    }
}

export default reducer;