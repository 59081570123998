export default {
    loginForm: {
        username: {
            name: 'username',
            elementType: 'input', 
            elementConfig: { type: 'text', placeholder: 'Username', icon: 'User' } , 
            value: '',
            styling: ['form-control login-control'],
            validation: { required: 'true'},
            valid: false,
            touched: false
        },
        password: {
            name: 'password',
            elementType: 'input', 
            elementConfig: { type: 'password', placeholder: 'Password', icon: 'Lock' } , 
            value: '',
            styling: ['form-control login-control'],
            validation: { required: 'true', minLength: 8 },
            valid: false,
            touched: false
        }
    },
    confirmForm: {
        newPassword: { 
            elementType: 'input', 
            elementConfig: { type: 'password', placeholder: 'New Password', icon: 'Lock', name: 'newPassword' } , 
            value: '',
            styling: ['form-control login-control', 'form-control login-control invalid-input'],
            validation: { required: true, minLength: 8 },
            valid: false,
            touched: false
        },
        confirmPassword: { 
            elementType: 'input', 
            elementConfig: { type: 'password', placeholder: 'Confirm Password', icon: 'Lock', name: 'confirmPassword' } , 
            value: '',
            styling: ['form-control login-control', 'form-control login-control invalid-input'],
            validation: { required: true, minLength: 8 },
            valid: false,
            touched: false
        }
    },
    buttons: {
        name: 'Login',
        elementType: 'button',
        elementConfig: { type: 'submit', name: 'Login'},
        label: 'Login',
        styling:  'btn btn-success btn-block text-uppercase',
        icon: 'SignIn'
    },
    formValid: true,
    redirect: false
}
