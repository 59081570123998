import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Logout from '../Authentication/Logout';
// Redux Actions
import * as actions from '../../Store/Actions/index';
//Icons
import Icon from '../../Components/UI/Icon';

class Toolbar extends Component {
    state = { 
        clients: [],
        tableHeader: ['First', 'Last', 'email', 'actions']
    }

    render() {
        // Check Group Roles
        let clientFiles = null;
        let clientManager = null;
    
        if (this.props.groups) {
            let adminRole;
            for (const role of this.props.groups) {
                if (role === 'admins') {
                    adminRole = true;
                    break;
                }
               else adminRole = false;
            }
            if (adminRole) {
                clientFiles   =  (<li className="sidebar-item">
                                    <Link className="sidebar-link" to="/clientfilemanager">
                                        <Icon icon="Folder"></Icon>
                                        <span className="link-name">Client Files</span>
                                    </Link>
                                </li>)
                clientManager = (<li className="sidebar-item">
                                <Link className="sidebar-link" to="/clientmanager">
                                    <Icon icon="Users"></Icon>
                                    <span className="link-name">Client Manager</span>
                                </Link>
                            </li>)

            }
        }
        
        return (
            <aside className="left-sidebar">
                <div className="scroll-sidebar">
                    <div className="d-flex flex-column sidebar-nav">
                        <ul className="nav">
                            <li className="nav-small-cap">
                                <Icon icon="Dots"></Icon>
                                <span className="hide-menu">File Manager</span>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link" to="/filemanager">
                                    <Icon icon="Folder"></Icon>
                                    <span className="link-name">My Files</span>
                                </Link>
                            </li>
                            {clientFiles}
                            <li className="nav-small-cap">
                                <Icon icon="Dots"></Icon>
                                <span className="hide-menu">Other</span>
                            </li>
                            {clientManager}
                            <li className="sidebar-item">
                                <Link className="sidebar-link" to="/profile">
                                    <Icon icon="Cogs"></Icon>
                                    <span className="link-name">Profile</span>
                                </Link>
                            </li>
                            <li className="sidebar-item">
                                <Logout icon="SignOut" label="Logout" />
                            </li>

                        </ul>
                    </div>
                </div>
            </aside>

        )
    }
}

//This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        groups: state.authenticate.groups,
    }
}

// This maps Props within Container to Redux State
// Output of component, call functions in the Store->Actions->Function
const mapDispatchtoProps = (dispatch) => {
    return {
    // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
        onLoad: (loading) => dispatch(actions.Loading(loading))
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Toolbar);