// Redux ACTION FILE
// Loading Screen
import * as actionTypes from './actionTypes';

export const Loading = (load) => {
    
    if (load) {
        return {
            type: actionTypes.LOADING_START,
            load: true
        }
    }

    else 
        return {
            type: actionTypes.LOADING_FINISHED,
            load: false
        }
}

export default Loading;