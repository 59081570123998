// Initialization
export const INITIALIZED                = 'INITIALIZED';
export const NOT_INITIALIZED            = 'NOT_INITIALIZED';

// Loading
export const LOADING_START              = 'LOADING_START';
export const LOADING_FINISHED           = 'LOADING_FINISHED';

// Authentication
export const REGISTRATION_START         = 'REGISTRATION_START';
export const REGISTRATION_FAILURE       = 'REGISTRATION_FAILURE';

export const VERIFY_START               = 'VERIFY_START';
export const VERIFY_STOP                = 'VERIFY_STOP';
export const VERIFY_SUCCESS             = 'VERIFY_SUCCESS';
export const VERIFY_FAILURE             = 'VERIFY_FAILURE';

export const AUTHENTICATE_START         = 'AUTHENTICATE_START';
export const AUTHENTICATE_SUCCESS       = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILURE       = 'AUTHENTICATE_FAILURE';
export const AUTHENTICATE_ERASE_KEY     = 'AUTHENTICATE_ERASE_KEY';
export const AUTHENTICATE_CLEAR_USER    = 'AUTHENTICATE_CLEAR_USER';
export const AUTHENTICATE_LOGOUT        = 'AUTHENTICATE_LOGOUT';

export const PASSWORD_RESET_START       = 'PASSWORD_RESET_START';
export const PASSWORD_RESET_SUCCESS     = 'PASSWORD_RESET_SUCCESS';

export const REFRESH_TOKEN_TIMER        = 'REFRESH_TOKEN_TIMER'