import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import ClientManagerState from '../StateConfiguration/ClientManagerState';
import UserTable from '../../Components/Tables/UserTable';
import UserCard from '../../Components/Cards/UserCard';
import Button from '../../Components/UI/Buttons';
// Redux Actions
import * as actions from '../../Store/Actions/index';
import Icon from '../../Components/UI/Icon';

class ClientManager extends Component {
    // ES7 Notation -- Put this in the state
    appName = 'clientmanager/';
    LIST_CLIENTS = 'listClients';
    state = ClientManagerState;

    componentDidMount() {
        // RWC - Only check Authentication in the Side Nav Toolbar
        // Mounted, Authenticated and NOT Loading, time to retrieve Files/Folders
        if ( this.props.authenticated) {
            this.AxiosGETHandler(this.LIST_CLIENTS);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // Initialize the Booleans
        let loadingTransition = false;
        let initComplete = false;
        let userSelect = false;
        let editForm = false;

        // Booleans
        // Loading transition has occurred
        loadingTransition = (nextProps.loading && !this.props.loading) || (!nextProps.loading && this.props.loading);
        // Just initialized
        initComplete = nextProps.initialized;
        // Selected new User
        userSelect = nextState.selectedUser !== this.state.selectedUser;
        // Edit the user 
        editForm = nextState.editForm !== this.state.editForm;

        // Condition to determine if Updating is needed
        if ( loadingTransition )
            return true
        else if ( initComplete )
            return true
        else if ( userSelect )  // Select a user
            return true
        else if ( editForm )  // Edit selected user
            return true  
        else 
            return false
    }

    componentDidUpdate(prevProps, prevState) {
        // ONLY call AWS if we are authenticated
        if (this.props.authenticated) {
            // Axios call conditions
            // 1. No Contents - we just refreshed
            if (prevProps.initialized === false && this.props.initialized === true && !this.props.loading) {
                this.AxiosGETHandler(this.LIST_CLIENTS);
            }
        }
    }

    ButtonHandler(event, key) {
        let userButton;
        let toggleButton;

        // console.log(event.currentTarget.name);
        // console.log(key)
 
        switch(event.currentTarget.name) {
            case('New'):
                this.props.history.push( this.props.location.pathname + '/new');
                break;
            case('Select'):
                //this.state.Users[key].Enabled ? toggleButton = 1 : toggleButton = 0;
                //userButton = this.state.UserButtons;
                //userButton[1].toggle = toggleButton;
                this.setState({...this.state, selectedUser: this.state.Users[key], userCard: true} );
                break;
            case('Edit'):
                // Copy SelectedUser to FormInputs
                // 1st clone the formInputs
                const formInput = this.state.formInputs;
                for (let input in formInput) {
                    //2nd Copy the selected User to the From
                    formInput[input].value = this.state.selectedUser[input]
                }
                // Change the Button State
                userButton = this.state.userCardButtons[0];
                toggleButton = userButton.toggle = 1;
                userButton.toggle = toggleButton;
                this.setState({...this.state, userButton, editForm: true});
                break;
            case('Enable'):
                // Perform a 'Deep' Clone of the User
                const selectedUser = this.state.selectedUser;
                const newStatus = !selectedUser.Enabled;
                selectedUser.Enabled = newStatus;
                // Change the Button State
                userButton = this.state.userCardButtons[1];
                toggleButton = !userButton.toggle;
                userButton.toggle = toggleButton;
                this.setState({...this.state, selectedUser: selectedUser, userButton});
                this.AxiosPOSTHandler('statusChange');
                break;
            case('Save'):
                this.setState({editForm: false});
                // Change the Button State
                userButton = this.state.userCardButtons[0];
                toggleButton = userButton.toggle;
                toggleButton = 0;
                userButton.toggle = toggleButton;
                this.setState({...this.state, userButton, editForm: false});
                this.AxiosPOSTHandler('editAttributes');
                break;
            case('Close'):
                if (this.state.editForm) {
                    //Change the edit/save button state
                    userButton = this.state.userCardButtons[0];
                    toggleButton = userButton.toggle;
                    toggleButton = 0;
                    userButton.toggle = toggleButton;
                    //Close the editForm
                    this.setState({editForm: false, userButton});
                }
                else 
                    //Close the User Card
                    this.setState({userCard: false})
                break;
            default:
                break;
        }
    }

    // RWC - Simple help function to clean up Object returned by Cognito
    FormatUsers = (users) => {
        const Clients = users.Users.map( client => {
            client.Attributes.forEach( attribute => {
                client[attribute.Name] = attribute.Value;
            });
            return client
        })
        return Clients
    }

    onChangeHandler = (event, key) => {
        //Copy the state of Selected User
        const selectedInput = {...this.state.formInputs};
        //Copy the specific control using the name
        const updatedElement = selectedInput[key];
        //Update just the value of that control, Grab the value of the event input
        updatedElement.value = event.target.value;
        //Copy the updated value back to the controls
        selectedInput[key] = updatedElement;
        //Update the STATE with by cloning current state, then copy ALL controls
        this.setState({...this.state, formInputs: selectedInput});
    }

    AxiosGETHandler = (action) => {
        const queryParam = '?action=' + action;
        this.props.onLoad(true);
        axios.get(this.appName+queryParam, {headers: {Authorization: this.props.idToken}}).then( (response) => {
                const Clients = this.FormatUsers(response.data)
                this.setState( {...this.state, Users: Clients}, () => this.props.onLoad(false) );  
            })
            .catch( (error) => {
                console.log(error);
            })
    }

    AxiosPOSTHandler = (command) => {
        let requestBody;
        console.log(command)
        switch(command) {
            case('statusChange'):
                requestBody = {
                    action: command,
                    user: this.state.selectedUser
                };
                break;
            case('editAttributes'):
                requestBody = {
                    action: command,
                    user: {
                        Username: this.state.selectedUser.Username,
                        name: this.state.formInputs.name.value,
                        family_name: this.state.formInputs.family_name.value,
                        email: this.state.formInputs.email.value
                    }
                };
                break;
            default:
                break;
        }
        
        console.log(requestBody);
        this.props.onLoad(true);
        axios({
            method: 'post',
            url: this.appName,
            data: JSON.stringify(requestBody),
            headers: {
                'content-type': 'application/json',
                Authorization: this.props.idToken
                }
        }).then( (response) => {
            console.log('Response: ', response.data);
            this.props.onLoad(false);
        })
        .catch( (error) => {
            console.log(error);
            this.props.onLoad(false);
        })
    }

    render() {
        let clientManager;
        if (this.state.userCard) {
            clientManager = <UserCard 
                                Buttons={this.state.userCardButtons}
                                User={this.state.selectedUser}
                                enabled={!this.state.selectedUser.Enabled}
                                Edit={this.state.editForm}
                                Inputs={this.state.formInputs}
                                buttonHandler={ (event) => this.ButtonHandler(event)}
                                onChangeHandler={ (event, key) => this.onChangeHandler(event, key) }
                            />

        } else {
            clientManager = <UserTable  
                                Header={this.state.tableHeader} 
                                Users={this.state.Users}
                                TableButtons={this.state.TableButtons}
                                loading={this.props.loading}
                                buttonHandler={ (event, key, label) => this.ButtonHandler(event, key, label) }
                            />
        }
            
        return (
            <div className="container-fluid">
                <div className="row no-gutters p-3">
                    <div className="col-lg-12">
                        <div className="page-header-title">
                            <Icon icon="Database" size="2x"/>
                            <div className="page-title d-inline">
                                <h5>Client Manager</h5>
                                <span className="page-description">Create, Disable, and Reset Client accounts</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters p-3">
                    <div className="col-md-12">
                        <Link to={`${this.props.match.url}/newclient`}>
                            <Button 
                                elementType={this.state.NewClientButton.elementType}
                                elementConfig={this.state.NewClientButton.elementConfig}
                                label={this.state.NewClientButton.label}
                                styling={this.state.NewClientButton.styling}
                                icon={this.state.NewClientButton.icon}
                            />
                        </Link>
                    </div>
                </div>
                <div className="row no-gutters p-3">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                            {clientManager}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        initialized: state.initialization.initialized,
        loading: state.loading.load,
        idToken: state.authenticate.idToken,
        userID: state.authenticate.userID,
        authenticated: state.authenticate.idToken !== null
    }
}

// This maps Props within Container to Redux State
// Output of component, call functions in the Store->Actions->Function
const mapDispatchtoProps = (dispatch) => {
    return {
    // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
        getCurrentUser: () => dispatch(actions.getCurrentUser() ),
        onLoad: (loading) => dispatch(actions.Loading(loading))
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(ClientManager)