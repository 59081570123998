import React from 'react';
// Font Awesome Library
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Font Awesome
import { faFolder, faFileAlt, faSignInAlt, faSignOutAlt, faUser, faUsers, faCog, faCogs, faEllipsisH,
         faBell, faChevronDown, faCaretDown,  faFileDownload, faFolderOpen, faFileExport, 
         faFire, faFileUpload, faLock, faTimes, faExclamationTriangle, faInfo, faDatabase, faEdit, 
         faSave,faUserSecret,faThumbsDown, faUpload, faList, faKey, faEnvelope, faUserCog, 
         faBox} from '@fortawesome/free-solid-svg-icons'
import { faComment, faFile, faThumbsUp, } from '@fortawesome/free-regular-svg-icons'


const icon = (props) => {
    let icon = null;

     switch(props.icon) {

        case('User'):
            icon = <FontAwesomeIcon icon={faUser} size={props.size}/>
        break;
        case('Users'):
            icon = <FontAwesomeIcon icon={faUsers} size={props.size} />
            break;
        case('UserCog'):
            icon = <FontAwesomeIcon icon={faUserCog} className={props.Class} size={props.size} />
            break;  
        case('Box'):
            icon = <FontAwesomeIcon icon={faBox} size={props.size}/>
        break;
        case('Mail'):
            icon = <FontAwesomeIcon icon={faEnvelope} className={props.Class} size={props.size} />
            break;  
        case('Secret'):
            icon = <FontAwesomeIcon icon={faUserSecret} style={props.style} size={props.size} />
            break;
        case('Key'):
            icon = <FontAwesomeIcon icon={faKey} style={props.style} size={props.size} />
            break; 
        case('Lock'):
            icon = <FontAwesomeIcon icon={faLock} />
            break;
        case('Cog'):
            icon = <FontAwesomeIcon icon={faCog} />
            break;
        case('Cogs'):
            icon = <FontAwesomeIcon icon={faCogs} />
            break;
        case('Times'):
            icon = <FontAwesomeIcon icon={faTimes} className={props.Class} size={props.size}/>
            break;
        case('Bell'):
            icon = <FontAwesomeIcon icon={faBell} />
            break;
        case('Comment'):
            icon = <FontAwesomeIcon icon={faComment} />
            break;
        case('ChevronDown'):
            icon = <FontAwesomeIcon icon={faChevronDown} />
            break;
        case('CaretDown'):
            icon = <FontAwesomeIcon icon={faCaretDown} />
            break;
        case('SignOut'):
            icon = <FontAwesomeIcon icon={faSignOutAlt} size={props.size} onClick={props.clicked} />
            break;
        case('SignIn'):
            icon = <FontAwesomeIcon icon={faSignInAlt} />
            break;
        case('Dots'):
            icon = <FontAwesomeIcon icon={faEllipsisH} />
            break;
        case('FileO'):
            icon = <FontAwesomeIcon icon={faFileAlt} />
            break;
        case('File'):
            icon = <FontAwesomeIcon icon={faFile} />
            break;
        case('Folder'):
            icon = <FontAwesomeIcon icon={faFolder} />
            break;
        case('Download'):
            icon = <FontAwesomeIcon icon={faFileDownload} />
            break;
        case('Open'):
            icon = <FontAwesomeIcon icon={faFolderOpen} />
            break;
        case('View'):
            icon = <FontAwesomeIcon icon={faFileAlt} />
            break;
        case('Move'):
            icon = <FontAwesomeIcon icon={faFileExport} />
            break;
        case('Rename'):
            icon = <FontAwesomeIcon icon={faFileAlt} />
            break;
        case('Delete'):
            icon = <FontAwesomeIcon icon={faFire} />
            break;
        case('Upload'):
            icon = <FontAwesomeIcon icon={faUpload} style={props.style}/>
            break;
        case('FileUpload'):
            icon = <FontAwesomeIcon icon={faFileUpload} />
            break;
        case('Exclamation'):
            icon = <FontAwesomeIcon icon={faExclamationTriangle} style={props.style} />
            break;
        case('Info'):
            icon = <FontAwesomeIcon icon={faInfo} style={props.style} />
            break;
        case('Database'):
            icon = <FontAwesomeIcon icon={faDatabase} style={props.style} size={props.size} />
            break; 
        case('Edit'):
            icon = <FontAwesomeIcon icon={faEdit} style={props.style} size={props.size} />
            break; 
        case('Save'):
            icon = <FontAwesomeIcon icon={faSave} style={props.style} size={props.size} />
            break; 
        case('ThumbsUp'):
            icon = <FontAwesomeIcon icon={faThumbsUp} style={props.style} size={props.size} />
            break; 
        case('ThumbsDown'):
            icon = <FontAwesomeIcon icon={faThumbsDown} style={props.style} size={props.size} />
            break;  
        case('List'):
            icon = <FontAwesomeIcon icon={faList} className={props.Class} size={props.size} />
            break;    
        default:
            icon = null
    }

    if (props.iconButton) {
        return (
            <button name={props.name} className={props.Class} type="button" onClick={props.clicked}>
                {icon}
            </button> 
        )
    }
    else {
        return (
            <span className="icon">
                {icon}
            </span>
        )
    }
};

export default icon;