// Reducer File for Authentication
import { REFRESH_TOKEN_TIMER } from "../Actions/actionTypes";

// Initial State required
const initialState = {
    refreshTokenTimer: false
}

export const refreshTokenTimer = (state, action) => {
    return {...state, 
        refreshTokenTimer: action.refreshTokenTimer
    }
}

// Simple Reducer Switch Statement
const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case REFRESH_TOKEN_TIMER: return refreshTokenTimer (state, action);

        default: return state
    }
}

export default reducer;