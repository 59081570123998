import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

const AdminRoute = ({component: WrappedComponent, ...rest} ) => {

    if (rest.groups) {
        let adminRole;
        for (const role of rest.groups) {
            if (role === 'admins') {
                adminRole = true;
                break;
            }
           else adminRole = false;
        }
        if (adminRole) {
            return (
                <Route {...rest} render = {
                    (props) => <WrappedComponent {...props} />
                } />
            )
        }
        else return <Redirect to="/home" />
    } 
    else return <div></div>

    
}



 // This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        groups: state.authenticate.groups,
    }
}

 export default connect(mapStatetoProps, null)(AdminRoute);