// Reducer File for Authentication
import { AUTHENTICATE_START, AUTHENTICATE_SUCCESS, AUTHENTICATE_FAILURE, AUTHENTICATE_LOGOUT } from '../Actions/actionTypes';
import { REGISTRATION_START, REGISTRATION_FAILURE, VERIFY_START, VERIFY_SUCCESS, VERIFY_FAILURE } from '../Actions/actionTypes';
import { PASSWORD_RESET_START, PASSWORD_RESET_SUCCESS } from '../Actions/actionTypes';

// Initial State required
const initialState = {
    idToken: null,
    refreshToken: null,
    tokenExpire: null,
    userID: null,
    name: null,
    family_name: null,
    email: null,
    groups: null,
    error: null,
    password_reset: false
}
// Consolidate Reducer functions
export const authStart = (state, action) => {
    return {...state, 
        error: null, 
        userID: action.userID,
    }
}
export const authSuccess = (state, action) => {
    return {...state, 
        idToken: action.idToken,
        refreshToken: action.refreshToken,
        tokenExpire: action.tokenExpire,
        userID: action.userID,
        name: action.name,
        family_name: action.family_name,
        email: action.email,
        groups: action.groups
    }
}
export const authFailure = (state, action) => {
    return {...state,
        refreshToken: action.refreshToken,
        tokenExpire: action.tokenExpire,
        userID: action.userID,
        name: action.name,
        family_name: action.family_name,
        email: action.email,
        groups: action.groups,
        error: action.error,
        }
}
export const authLogout = (state, action) => {
    return {...state, 
        idToken: null,
        refreshToken: null,
        tokenExpire: null,
        userID: null,
        name: null,
        family_name: null,
        email: null,
        groups: null,
        error: null,
    }
}
export const passwordResetStart = (state, action) => {
    return {...state, 
        idToken: null, 
        userID: action.userID,
        name: action.name,
        family_name: action.family_name,
        email: action.email,
        password_reset: action.password_reset
    }
}
export const passwordResetSuccess = (state, action) => {
    return {...state, 
        idToken: null, 
        userID: action.userID,
        password_reset: action.password_reset
    }
}



export const refreshToken = (state, action) => {
    return {...state,
            refresh: action.refresh
        }
}






export const regStart = (state, action) => {
    return {...state, 
        idToken: null, 
        userID: null
    }
}
export const regFail = (state, action) => {
    return {...state, 
        error: action.error
    }
}
export const verifyStart = (state, action) => {
    console.log(action)
    return {...state, 
        userID: action.userID    
    }
}
export const verifySuccess = (state, action) => {
    return {...state,
    }
}

export const verifyFail = (state, action) => {
    return {...state, 
            error: action.error
        }
}

// Simple Reducer Switch Statement
const reducer = (state = initialState, action) => {

    switch ( action.type ) {
        case AUTHENTICATE_START: return authStart (state, action);
        case AUTHENTICATE_SUCCESS: return authSuccess (state, action);
        case AUTHENTICATE_FAILURE: return authFailure (state, action);
        case AUTHENTICATE_LOGOUT: return authLogout (state, action);

        case PASSWORD_RESET_SUCCESS: return passwordResetSuccess (state, action);
        case PASSWORD_RESET_START: return passwordResetStart (state, action);

        case REGISTRATION_START: return regStart (state, action);
        case REGISTRATION_FAILURE: return regFail (state, action);

        case VERIFY_START: return verifyStart (state, action);
        case VERIFY_SUCCESS: return verifySuccess (state, action);
        case VERIFY_FAILURE: return verifyFail (state, action);
        
        default: return state
    }
}

export default reducer;