import React from 'react';

const footer = () => {

    const date = new Date();
    const year = date.getFullYear();

    return (
        <div className="login-footer">
            © {year} <strong>Rambeau Interior Design</strong>
            <br/>
            All rights reserved.
        </div>
    )
}

export default footer;