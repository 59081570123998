import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import NewClientState from '../StateConfiguration/NewClientState';
import * as actions from '../../Store/Actions/index';

import Input from '../../Components/UI/Input';
import Button from '../../Components/UI/Buttons';
import Icon from '../../Components/UI/Icon';
import Spinner from '../../Components/UI/Spinner';
import { InputValidityCheck } from '../../Functions/ValidityCheckers';

class NewClient extends Component {

    state = NewClientState;

    onChangeHandler (event, key) {
        //Copy the state
        const updatedInputs = {...this.state.newClientForm};
        //Copy the specific control using the name
        const updatedElement = {...updatedInputs[key]};
        //Update just the value of that control
        updatedElement.value = event.target.value;
        //Check Validity
        updatedElement.valid = this.checkValidity(updatedElement.value, updatedElement.validation);
        //Copy the updated value back to the controls
        updatedInputs[key] = updatedElement;
        //Check FormValidity
        let formElementValidity = true;
        for (let key in updatedInputs) {
            formElementValidity = this.state.newClientForm[key].valid && formElementValidity;
        }
        //Update the STATE with by cloning current state, then copy ALL controls
        this.setState({...this.state, newClientForm: updatedInputs, formValid: formElementValidity});
    }

    onBlurHandler (event) {
        const key = event.currentTarget.name;
        const value = event.currentTarget.value;
    
        //Copy the state
        const updatedInputs = {...this.state.newClientForm};
        //Copy the specific control using the name
        const updatedElement = {...updatedInputs[key]};
        // Check Validity
        updatedElement.valid = InputValidityCheck(value, this.state.newClientForm[key].validation);
        // Input is NOW touched
        updatedElement.touched = true;
        //Copy the updated value back to the controls
        updatedInputs[key] = updatedElement;
        //Update the STATE with by cloning current state, then copy ALL controls
        this.setState({...this.state, newClientForm: updatedInputs});
    }

    // Check the input validity based on state-defined rules
    checkValidity = (value, rules) => InputValidityCheck(value, rules);
    
    SubmitFormHandler = (event) => {
        event.preventDefault()
        // Dispatch the Cognito Sign in component
        this.AxiosPOSTHandler();
    }

    AxiosPOSTHandler = () => {
        const requestBody = {
            action: 'newClient',
            user: {
                Username: this.state.newClientForm.username.value,
                name: this.state.newClientForm.firstname.value,
                family_name: this.state.newClientForm.lastname.value,
                email: this.state.newClientForm.email.value
            }
        }
        this.props.onLoad(true);
        axios({
            method: 'post',
            url: '/clientmanager/newclient',
            data: JSON.stringify(requestBody),
            headers: {
                'content-type': 'application/json',
                Authorization: this.props.idToken
                }
        }).then( (response) => {
            console.log('Response: ', response.data);
            if(response.data.statusCode === 200) {
                this.props.history.push(`/clientmanager`);
            }
            this.props.onLoad(false);
        })
        .catch( (error) => {
            console.log(error);
            this.props.onLoad(false);
        })
    }

    render() {
        let formElementsArray = [];
        for (let formElement in this.state.newClientForm) {
            formElementsArray.push({key: formElement, config: this.state.newClientForm[formElement]});  // push the WHOLE object
        }

        //Build Form
        const form = formElementsArray.map( formElement => {
            return (
                <Input 
                    key={formElement.key} 
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    styling={formElement.config.styling}
                    value={formElement.config.value}
                    validation={formElement.config.validation}
                    valid={formElement.config.valid}
                    touched={formElement.config.touched}
                    changed={ (event) => this.onChangeHandler(event, formElement.key) }
                    blur={ (event) => this.onBlurHandler(event) } 
                />
            )
        });

        return (
            <div className="container-fluid">
                <div className="row no-gutters p-3">
                    <div className="col-lg-12">
                        <div className="page-header-title">
                            <Icon icon="Users" size="2x"/>
                            <div className="page-title d-inline">
                                <h5>New Client</h5>
                                <span className="page-description">Create a New Client Account</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters justify-content-center px-3 pt-5">
                    <div className="col-lg-6 col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-title px-5">
                                    <p>Enter Information</p>
                                </div>
                                <form className="mt-3 px-5" onSubmit={this.SubmitFormHandler}>
                                    {form}
                                    <div className="card-footer bg-transparent border-success d-flex justify-content-center">
                                    <Button 
                                        key={this.state.buttons.create.name} 
                                        elementType={this.state.buttons.create.elementType}
                                        elementConfig={this.state.buttons.create.elementConfig}
                                        styling={this.state.buttons.create.styling}
                                        label={this.state.buttons.create.label}
                                        icon={this.state.buttons.create.icon}
                                        disabled={!this.state.formValid || this.props.loading}
                                    />
                                    <Link to="/clientmanager">
                                        <Button 
                                            key={this.state.buttons.cancel.name} 
                                            elementType={this.state.buttons.cancel.elementType}
                                            elementConfig={this.state.buttons.cancel.elementConfig}
                                            styling={this.state.buttons.cancel.styling}
                                            label={this.state.buttons.cancel.label}
                                            icon={this.state.buttons.cancel.icon}
                                        />
                                    </Link>
                                    </div>
                                </form>
                                <Spinner name='loading' loading={this.props.loading}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


//This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        loading: state.loading.load,
        userID: state.authenticate.userID,
        idToken: state.authenticate.idToken
    }
}

// This maps Props within Container to Redux State
// Output of component, call functions in the Store->Actions->Function
const mapDispatchtoProps = (dispatch) => {
    return {
    // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
        onLoad: (loading) => dispatch(actions.Loading(loading))
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(NewClient);