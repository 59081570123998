import React from 'react';

const spinner = (props) => {
    let spinner = null;
    let spinnerWrapperStyle = (props.loading) ? 'spinner-wrapper' : 'spinner-wrapper-hide';

    switch (props.name) {
        
        case('loading'):
            spinner = <div className="app-spinner loading"></div>
            break;
        case('loading-primary'):
            spinner = <div className="app-spinner loading loading-primary"></div>
            break;
        case('loading-danger'):
                spinner = <div className="app-spinner loading loading-danger"></div>
            break;

        default: 
            spinner = <div className="app-spinner loading"></div>
    }

    return (
        <div className={spinnerWrapperStyle}>
            {spinner}
        </div>
    )

};
    
export default spinner;