import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// Redux Actions
import * as actions from '../Store/Actions/index';

const RequiresAuthentication = (WrappedComponent) => {

    class Authenticate extends Component {

        componentDidMount() {
            // Use the client/username as the base for the Folder Applet
            //console.log(this.props.authenticated,this.props.initialized,this.props.userID,this.props.refreshToken )
            if ( !this.props.authenticated && !this.props.initialized && (this.props.userID == null) ) {
                this.props.getCurrentUser();
            }
        }

        shouldComponentUpdate(nextProps, nextState) {
            // Initialize the Booleans
            let loadingTransition = false;
            let initComplete = false;
            let tokenExpired = false;
    
            // Booleans
            // Loading transition has occurred
            loadingTransition = (nextProps.loading && !this.props.loading) || (!nextProps.loading && this.props.loading);
            // Completed Initialization, meaning we have a valid USERID...but we are still in Loading state
            initComplete = (nextProps.initialized && nextProps.loading);
            // The Refresh token is no longer valid
            tokenExpired = !this.props.authenticated;
    
            // Condition to determine if Updating is needed
            if ( loadingTransition )
                return true 
            else if ( initComplete ) // Initialization is complete (valid User) or (failure), but we havent called AWS
                return true
            else if ( tokenExpired )  // Authentication failed  -- RWC move this to App.js? -- Need to add a redirect
                return true
            else 
                return false
        }
    
        componentDidUpdate(prevProps, prevState) {
            // ONLY call AWS if we are authenticated
            if ( !this.props.authenticated && (prevProps.initialized !== this.props.initialized) && !this.props.loading) {
                // Authentication Check failed, so trigger a load
                // RWC---Do we need this?
                //this.props.onLoad(true);
            } 
        }

        render () {
            // Redirect to Login if no idToken and Init/Loading are finished
            if ( !this.props.authenticated && this.props.initialized )
                return <Redirect to ="/login" />
            else {
                return (
                    <WrappedComponent {...this.props} />
                )
            }
        }  
    }

    //This maps Props within Container to Redux Dispatches
    // Input to component, access these with this.props
    const mapStatetoProps = (state) => {
        return {
            initialized: state.initialization.initialized,
            //error: state.authenticate.error,
            idToken: state.authenticate.idToken,
            userID: state.authenticate.userID,
            authenticated: state.authenticate.idToken !== null,
            refreshToken: state.authenticate.refreshToken,
        }
    }

    // This maps Props within Container to Redux State
    // Output of component, call functions in the Store->Actions->Function
    const mapDispatchtoProps = (dispatch) => {
        return {
        // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
            getCurrentUser: () => dispatch(actions.getCurrentUser()),
            onLoad: (loading) => dispatch(actions.Loading(loading)),
            tokenRefresh: (refreshToken) => dispatch(actions.refreshTokenTimerStart(refreshToken)) 
        }
    }
    return connect(mapStatetoProps, mapDispatchtoProps)(Authenticate);
}

export default RequiresAuthentication;