import React from 'react';
import Layout from '../../HOC/Layout';
import Icon from './Icon';

const button = (props) => {
    let inputElement = null;

    switch(props.elementType) {
        case ('button'):
            inputElement = 
                <button className={props.styling} {...props.elementConfig} disabled={props.disabled} onClick={props.clicked}>
                    <Icon icon={props.icon} Class={props.iconClass}></Icon>
                    {props.label}
                </button>     
            break;

        case ('button-toggle'):
            if (props.toggle) {
                inputElement = <button className={props.styling[1]} {...props.elementConfig[1]} onClick={props.clicked}> 
                <Icon icon={props.icon[1]}></Icon>
                {props.label[1]} 
                </button>
            }

            else
                inputElement = <button className={props.styling[0]} {...props.elementConfig[0]} onClick={props.clicked}>
                <Icon icon={props.icon[0]}></Icon>
                {props.label[0]}
                </button>
            break;
        default:
            inputElement = <button onClick={props.clicked}>{props.name}</button>
            break;
    }


    switch(props.elementType) {
        case('button'):
            return (
                <Layout>
                    {inputElement}
                </Layout>
            )
            case('button-toggle'):
            return (
                <Layout>
                    {inputElement}
                </Layout>
            )

        default:
            return (
                <div></div>
            )
    }

};

export default button;