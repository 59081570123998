// Redux ACTION FILE
// Timers
import * as actionTypes from './actionTypes';
//import { tokenRefresh } from './authentication';

export const refreshTimerStart = (active) => {
    return  {
        type: actionTypes.REFRESH_TOKEN_TIMER,
        refreshTokenTimer: active
    }
}

export const refreshTokenTimerStart = (refreshToken) => { 
    return dispatch => {
        //console.log('Refresh Timer START');
        // dispatch(refreshTimerStart(true));
    
        // setInterval( () => {
        //     console.log('REFRESHING TOKEN');
        //     dispatch(tokenRefresh(refreshToken));
        // }, 600000);  
        // 600000   = 10 minutes
        // 1800000  = 30 minutes
        // 2700000  = 45 minutes
        // 3300000  = 55 minutes
    }
}
