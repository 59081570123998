import React from 'react';
import Button from '../../Components/UI/Buttons';
//import Icon from '../../Components/UI/Icon';

const ContentViewer = (props) => {

    let contentShowHide;
    if (props.show) contentShowHide = "content-viewer-container show";
    else contentShowHide = "content-viewer-container hide";

    return (
        <div className={contentShowHide}>
            <div className="toolbar">
                <div className="mx-3 d-flex justify-content-end">
                    <Button elementType={props.button.elementType}
                            elementConfig={props.button.elementConfig}
                            styling={props.button.styling}
                            icon={props.button.icon}
                            iconClass={props.button.iconClass}
                            clicked={props.clicked}>
                    {/* <Icon icon="Times" Class="icon-light" /> */}
                    </Button>
                    
                </div>
            </div>
            <div className="wrapper">
                <object
                    className="object mx-auto"
                    data={props.URL}
                    type={props.type}
                    width="100%"
                    height="100%"
                    >
                    <p>Your browser does not support this filetype.
                        <a href={props.URL}>Download the file</a>.</p>
                </object>
            </div>
        </div>

    )

}
export default ContentViewer;