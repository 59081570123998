import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Logout from '../Authentication/Logout';
import Icon from '../../Components/UI/Icon';

import flower from '../../Assets/Extra/flower.jpg'

class HeaderBar extends Component {

    state = {
        dropDownMenu : {
            show: false,
            styling : ['show-notification notification-view dropdown-menu', 'show-notification notification-view dropdown-menu show']
        }
    }

    onClickHandler = (event) => {
        console.log(event.currentTarget.id);;
        switch (event.currentTarget.id) {
            case('profile'):
                let dropDownMenu = {...this.state.dropDownMenu};
                dropDownMenu.show = !dropDownMenu.show;
                this.setState({dropDownMenu: dropDownMenu})
                break;
            default: 

        }   
    }

    render () {
        let dropDownMenuStyling = this.state.dropDownMenu.styling[0]
        if (this.state.dropDownMenu.show) 
            dropDownMenuStyling = this.state.dropDownMenu.styling[1]

        return (
            <header className="header-container">
                <div className="navbar header-navbar">
                    <div className="navbar-wrapper">
                        <div className="navbar-logo">
                            <Link to="/home"><div className="rambeau-logo"></div></Link>
                        </div>
                        <div className="navbar-container container-fluid">
                            <ul className="nav-right">
                                <li className="header-notification navbar-hide">
                                    <div className="dropdown-primary dropdown">
                                        <div className="dropdown-toggle">
                                            <Icon icon="Bell" size="20"/>
                                            <span className="badge badge-danger">1</span>
                                        </div>
                                        <ul className="show-notification notification-view dropdown-menu" >
                                            <li>
                                                <h6>Notifications</h6>
                                                <label className="label label-danger">New</label>
                                            </li>
                                            <li>
                                                <div className="media">
                                                    <img className="img-radius" src={flower} alt=""></img>
                                                    <div className="media-body">
                                                        <h5 className="notification-user">John Doe</h5>
                                                        <p className="notification-msg">New file uploaded</p>
                                                        <span className="notification-time">30 minutes ago</span>
                                                    </div>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </li>
                                <li className="header-notification navbar-hide">
                                    <div className="dropdown-primary dropdown">
                                        <div className="displayChatbox dropdown-toggle" data-toggle="dropdown">
                                            <Icon icon="Comment" size="20"/>
                                        </div>
                                    </div>
                                </li>

                                <li  id="profile" className="user-profile header-notification"onClick={(event) => this.onClickHandler(event)}>
                                    <div className="dropdown-primary dropdown">
                                        <div className="dropdown-toggle">
                                            <img src={flower} className="img-radius" alt=""></img>
                                            <span className="username-box">{this.props.firstname}</span>
                                            <Icon icon="ChevronDown" size="10"/>
                                        </div>
                                        <ul className={dropDownMenuStyling} >
                                            <li>
                                                <Link to="/profile" className="d-flex">
                                            
                                                        <Icon icon="User"/>
                                                    
                                                    <span className="link-name">
                                                        Profile
                                                    </span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Logout className="d-flex" icon="SignOut">Logout</Logout>
                                            </li>
                                                
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

//This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        loading: state.loading.loading,
        userID: state.authenticate.userID,
        firstname: state.authenticate.name,
        lastname: state.authenticate.family_name
    }
}

export default connect(mapStatetoProps)(HeaderBar);