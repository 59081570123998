// React Packages
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './Store/Actions/index';
//HOC
import RambeauCloud from './HOC/RambeauCloud';
//CSS
import './Sass/styles.css';

class App extends Component {

	componentDidMount() {
    }

	render() {
		return (
			<BrowserRouter>
				<RambeauCloud />
			</BrowserRouter>
		);
	}
}

//This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        authenticated: state.authenticate.idToken !== null
    }
}

//This maps Props within Container to Redux State
// Output of component, call functions in the Store->Actions->Function
const mapDispatchtoProps = (dispatch) => {
    return {
    // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
        getCurrentUser: () => dispatch(actions.getCurrentUser() )
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(App);
