import React from 'react';
import Layout from '../../HOC/Layout';

import Icon from './Icon';

const input = (props) => {
    let inputElement = null;
    let inputStyling = props.styling;

    //console.log( !props.valid && props.validation && props.touched )

    if (!props.valid && props.validation && props.touched) {
        inputStyling = [props.styling[1]];
    }
    else 
        inputStyling = [props.styling[0]];

    switch(props.elementType) {
        case ('login'):
            inputElement = <input 
                                className={inputStyling} 
                                {...props.elementConfig} 
                                value={props.value} 
                                onChange={props.changed} 
                                />
            break;
        case ('input'):
            inputElement = <input 
                                className={inputStyling} 
                                {...props.elementConfig} 
                                value={props.value} 
                                onChange={props.changed} 
                                onBlur={props.blur}
                                />
            break;
        case ('input-noIcon'):
            inputElement = <input 
                                className={inputStyling} 
                                {...props.elementConfig} 
                                value={props.value}
                                onChange={props.changed} 
                                />
            break;
        case ('textArea'):
            inputElement = <textarea 
                                {...props.elementConfig} 
                                value={props.value}
                                onChange={props.changed}  
                                />
            break;
        case ('radio'):
            inputElement = <input 
                                {...props.elementConfig} 
                                className={inputStyling}
                                style={{'width': '16px'}}
                                value={props.value} 
                                checked={props.checked} 
                                onChange={props.changed} 
                                />
            break;
        default:
            inputElement = <button onClick={props.clicked}>{props.name}</button>
    }

    switch(props.elementType) {
        case('input'):
            return (
                <div className="login-group input-group mb-3">
                    <Icon icon={props.elementConfig.icon} />
                    {inputElement}
                </div>
            )
        case('input-noIcon'):
            return (
                <div className="input-group col-md-7 line-height-base">
                    {inputElement}
                </div>
            )

        case('radio'):
            return (
                <Layout>
                    {inputElement}
                </Layout>  
            )
        default:
            return (
                <div></div>
            )
    }

};

export default input;