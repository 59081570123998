// Simple wrapper to hold page components
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Login from '../Containers/Authentication/Login';
import ForgotPassword from '../Containers/Authentication/ForgotPassword';
// import ConfirmPassword from '../Containers/Authentication/ConfirmPassword';
// import ConfirmNewPassword from '../Containers/Authentication/ConfirmNewPassword';
import Register from '../Containers/Authentication/Register';
import Verify from '../Containers/Authentication/Verify';
import CloudManager from '../Containers/CloudManager/CloudManager';
import RequiresAuthentication from '../HOC/RequiresAuthentication'; // HOC for Authentication

const RambeauCloud = () => (
    <Switch>
        <Route path="/login" exact component={Login}/>
        <Route path="/forgotpassword" exact component={ForgotPassword}/>
        {/* <Route path="/confirmpassword" exact component={ConfirmPassword}/> */}
        {/* <Route path="/newpassword" exact component={ConfirmNewPassword}/> */}
        <Route path="/register" exact component={Register}/>
        <Route path="/verify" exact component={Verify}/>
        <Route path='/:app' component={RequiresAuthentication(CloudManager)}/>
        <Redirect from="/" to="/login" />
        <Route path="*" component={() => "404 NOT FOUND"} />
    </Switch>
);

export default RambeauCloud;