// Rambeau Cloud Forgot Password Page
import React, { Component } from 'react';
import { Redirect, Link} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../Store/Actions/index';
import ForgotPasswordState from '../StateConfiguration/ForgotPasswordState';

import Input from '../../Components/UI/Input';
import Button from '../../Components/UI/Buttons';
import Footer from '../../Components/UI/Footer';
import { InputValidityCheck } from '../../Functions/ValidityCheckers';

class ForgotPassword extends Component {
  
        state = ForgotPasswordState
    
        onChangeUsernameHandler (event, key) {
            //Copy the state
            const updatedControls = {...this.state.userForm};
            //Copy the specific control using the id
            const updatedElement = {...updatedControls[key]};
            //Update just the value of that control
            updatedElement.value = event.target.value;
            //Check Validity
            updatedElement.valid = this.checkValidity(updatedElement.value, updatedElement.validation);
            //Copy the updated value back to the controls
            updatedControls[key] = updatedElement;
            //Check FormValidity
            let formElementValidity = true;
            formElementValidity = updatedControls[key].valid && formElementValidity;
            this.setState({...this.state, userForm: updatedControls, formValid: formElementValidity});
        }

        onChangeResetPassHandler (event, key) {
            //Copy the state
            const updatedInputs = {...this.state.confirmForm};
            //Copy the specific control using the id
            const updatedElement = {...updatedInputs[key]};
            //Update just the value of that control
            updatedElement.value = event.target.value;
            //Check Validity of element
            updatedElement.valid = this.checkValidity(updatedElement.value, updatedElement.validation);
            //Copy the updated value back to the controls
            updatedInputs[key] = updatedElement;

            //Check the Form Validity, Create a 'Map' of valid and touched
            let formElementValidity = true;

            let validMap = new Map();
            // Create a Hash Map
            Object.entries(this.state.confirmForm).forEach(
                ([key, value]) => {
                    validMap.set(key, (value.valid) );
                }
            );
            //Update the Map with the current element valid state
            validMap.set(key,updatedElement.valid);
            if (event.target.name === 'confirmPassword') {
                if (updatedElement.value !== this.state.confirmForm.newPassword.value) {
                    validMap.set(event.target.name, false);
                }
                else validMap.set(event.target.name, true);
            }
            // Update the input controls for the state
            validMap.forEach( (value, key) => {
                updatedInputs[key].valid = value;
            });
            // Update the form Validity
            validMap.forEach( (value, key) => {
                formElementValidity = updatedInputs[key].valid && formElementValidity;
            });

            this.setState({...this.state, 
                            confirmForm: updatedInputs, 
                            formValid: formElementValidity,
                        });
        }

        onBlurHandler (event) {
            const key = event.currentTarget.name;
            const value = event.currentTarget.value;
            //Copy the state
            const updatedInputs = {...this.state.confirmForm};
            //Copy the specific control using the name
            const updatedElement = {...updatedInputs[key]};
            // Check Validity
            updatedElement.valid = InputValidityCheck(value, this.state.confirmForm[key].validation);
            // Input is NOW touched
            updatedElement.touched = true;
            //Copy the updated value back to the controls
            updatedInputs[key] = updatedElement;
            let message;  // Form input related message

            let formElementValidity = true;

            let validMap = new Map();
            // Create a Hash Map to check just the form validity
            Object.entries(this.state.confirmForm).forEach(
                ([key, value]) => {
                    validMap.set(key, (value.valid && value.touched) );
                }
            );
            //Update the Map with the current element touched state
            validMap.set(key,updatedElement.touched);

            //Check if Passwords Match for form Validity
            validMap.forEach( (value, key) => {
                if ( (key === 'newPassword') || (key === 'confirmPassword') ) {
                    if ( validMap.get('newPassword') && validMap.get('confirmPassword') ){
                        if ( this.state.confirmForm.newPassword.value !== this.state.confirmForm.confirmPassword.value) {
                            message = `The passwords don't match`;
                            formElementValidity = false;
                        } 
                        else {
                            message = null;
                            formElementValidity = true;
                        }
                    }
                }
                formElementValidity = value && formElementValidity;
            });

            //Update the STATE with by cloning current state, then copy ALL controls
            this.setState({...this.state, confirmForm: updatedInputs, formValid: formElementValidity, message: message});
        }
                
        // Check the input validity based on state-defined rules
        checkValidity = (value, rules) => InputValidityCheck(value, rules);
    
        ResetPasswordHandler = (event) => {
            // Block Default
            event.preventDefault();
            // Turn Form Validity back off
            this.setState({formValid: false});
             // Dispatch the Cognito Sign in component
            this.props.resetPass(this.state.userForm.username.value);
        }

        ComfirmPasswordHandler = (event) => {
            //Block Default
            event.preventDefault();
            // Dispatch the Cognito Sign in component
            this.props.confirmPass(this.state.userForm.username.value, this.state.confirmForm.newPassword.value, this.state.confirmForm.verificationCode.value);
            this.setState({...this.state, redirect: true})
        }
        shouldComponentUpdate(nextProps,nextState) {
            let loginFormStateChange = false;
            let passwordConfirmSuccess = false;
            let confirmNewPasswordForm = false;
            let redirect = false;
            let formValidity = false;

            // The reset Password call was successful
            passwordConfirmSuccess = this.props.password_reset && !nextProps.password_reset;
            // StateChange
            loginFormStateChange = (nextState.userForm !== this.state.userForm) || (nextState.confirmForm !== this.state.confirmForm);
            // Show the Confirm new Password form
            confirmNewPasswordForm = (nextProps.password_reset && !this.props.password_reset);
            // if redirect
            redirect = this.state.redirect;
            // Any chance in form validity
            formValidity = (nextState.formValid && !this.state.formValid) || (!nextState.formValid && this.state.formValid);

            if (passwordConfirmSuccess) return true
            else if (loginFormStateChange ) return true
            else if (confirmNewPasswordForm) return true
            else if (redirect) return true
            else if (formValidity) return true
            else return false
        }
    
        render () {
            //Login Form
            let resetPassFormElementsArray = [];
            for (let input in this.state.confirmForm) {
                resetPassFormElementsArray.push({key: input, config: this.state.confirmForm[input]});
            }

            let form = null;
            let resetPassForm = null;
            if (this.props.userID) {
                // Build the Reset Pass with Verification form
                resetPassForm = resetPassFormElementsArray.map( formElement => {
                    return ( 
                        <Input 
                            key={formElement.key} 
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            styling={formElement.config.styling}
                            value={formElement.config.value}
                            valid={formElement.config.valid}
                            validation={formElement.config.validation}
                            touched={formElement.config.touched}
                            changed={ (event) => this.onChangeResetPassHandler(event, formElement.key) }
                            blur={ (event) => this.onBlurHandler(event) } 
                        />
                    )
                });
                // Confirm Password, and Verfication Code Form
                form = (
                    <form className="mt-3" onSubmit={this.ComfirmPasswordHandler}>
                        <Input 
                            elementType={this.state.userForm.username.elementType}
                            elementConfig={this.state.userForm.username.elementConfig}
                            styling={this.state.userForm.username.styling}
                            validation={this.state.userForm.username.validation.required}
                            value={this.state.userForm.username.value} 
                            touched={this.state.userForm.username.touched}
                            changed={ (event) => this.onChangeUsernameHandler(event, this.state.userForm.username.elementConfig.name) }
                        />
                        {resetPassForm}
                        <p className="password-rules">
                            <span className="password-rules">Password Rules:</span>
                            <br/>
                            Minimum of 8 characters.
                            <br/>
                            One uppercase letter.
                            <br/>
                            One number.
                        </p>
                        <Button
                            elementType={this.state.submitButton.elementType}
                            elementConfig={this.state.submitButton.elementConfig}
                            label={this.state.submitButton.label}
                            styling={this.state.submitButton.styling}
                            disabled={!this.state.formValid}>
                        </Button>
                        {/* <button type="submit" className="btn btn-success btn-block text-uppercase" disabled={!this.state.formValid}>Submit</button> */}
                    </form>
                )
            } else {
                //Just the Username Form
                form = (
                    <form className="mt-3" onSubmit={this.ResetPasswordHandler}>
                        <Input 
                            elementType={this.state.userForm.username.elementType}
                            elementConfig={this.state.userForm.username.elementConfig}
                            styling={this.state.userForm.username.styling}
                            validation={this.state.userForm.username.validation.required}
                            value={this.state.userForm.username.value} 
                            touched={this.state.userForm.username.touched}
                            changed={ (event) => this.onChangeUsernameHandler(event, this.state.userForm.username.elementConfig.name) }
                        />
                        <br></br>
                        <Button
                            elementType={this.state.submitButton.elementType}
                            elementConfig={this.state.submitButton.elementConfig}
                            label={this.state.submitButton.label}
                            styling={this.state.submitButton.styling}
                            disabled={!this.state.formValid}>
                        </Button>
                        {/* <button type="submit" className="btn btn-success btn-block text-uppercase" disabled={!this.state.formValid}>Submit</button> */}
                    </form>
                )
            }

            //Redirect once Password change is successful
            let confirmPasswordRedirect = null;
            if (this.state.redirect) {
                confirmPasswordRedirect = <Redirect to ="/login" />
            }

            //Error Message Handling from redux
            let errorMessage = null;
            if (this.props.error) {
                errorMessage = (
                    <p>{this.props.error}</p>
                )
            }

            //Error Message Handling on form input
            let message = <p>{this.state.message}</p>

            return (
                <div className="login-wrapper">
                    <div className="login-box col">
                        {confirmPasswordRedirect}
                        <div className="card text-center card-form login-card">
                            <div className="card-body">
                            <div className="rambeau-cloud-logo" />
                            <div className="card-title">
                                <strong>Recover</strong>
                            </div>
                            <div className="password-message">{message}</div>
                            {errorMessage}
                            {form}
                            <br></br>
                            <p><Link to="login">Have an Account?</Link></p>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            )
        }
    }

// This maps Props within Container to Redux Dispatches
// Input to component, access these with this.props
const mapStatetoProps = (state) => {
    return {
        loading: state.loading.load,
        error: state.authenticate.error,
        userID: state.authenticate.userID != null,
        password_reset: state.authenticate.password_reset
    }
}

// This maps Props within Container to Redux State
// Output of component, call functions in the Store->Actions->Function
const mapDispatchtoProps = (dispatch) => {
    return {
    // Syntax --> Property : () => { dispatch({ type: 'ACTION' }) }
        resetPass: (username) => dispatch(actions.resetPassword(username)),
        confirmPass: (username, newPassword, verificationCode) => dispatch(actions.confirmPassword(username, newPassword, verificationCode)),
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(ForgotPassword);