// Reducer File for Authentication
import { LOADING_START, LOADING_FINISHED } from "../Actions/actionTypes";

// Initial State required
const initialState = {
    load: false
}

export const loadingStatus = (state, action) => {
    return {...state, 
        load: action.load
    }
}

// Simple Reducer Switch Statement
const reducer = (state = initialState, action) => {
    switch ( action.type ) {
        case LOADING_START: return loadingStatus (state, action);
        case LOADING_FINISHED: return loadingStatus (state, action);

        default: return state
    }
}

export default reducer;