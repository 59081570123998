import React from 'react';

const ProgressBar = (props) => {

    const pbar = { width: props.pBarWidth + '%' };

    return (
        <div className="progress-wrapper">
            <div className="progress">
                <div className="progress-bar" style={pbar}></div>
            </div> 
            <div className="message-wrapper">
                <p className="message">{props.message}</p>
            </div>
        </div>
    )
}

export default ProgressBar;