import React from 'react';
// UI Components
import Button from '../../Components/UI/Buttons';
import Spinner from '../../Components/UI/Spinner';
import Layout from '../../HOC/Layout';

const UserTable = (props) => {

    // Columns of the Table
    const tableHeader = props.Header.map( header => <th key={header.label} style={header.style}>{header.label}</th>);

    //Create the Buttons dynamically
    const tableButtonsArray = [];
    for (let key in props.TableButtons) {
        tableButtonsArray.push( {id: key, 
                            elementType: props.TableButtons[key].elementType, 
                            elementConfig: props.TableButtons[key].elementConfig,
                            label: props.TableButtons[key].label,
                            styling: props.TableButtons[key].styling,
                            icon: props.TableButtons[key].icon })
    };

    let users = null; // RWC need to have default JSX
    // 'Enabled' Label
    let enabledStatus;

    if (props.Users) {
        users = (
            props.Users.map( (user, index) => {
                if (user.Enabled === undefined) {
                    enabledStatus =  <span></span>
                } else if (user.Enabled === true) {
                    enabledStatus =  <span className="badge badge-success">Active</span>
                } else {
                    enabledStatus =  <span className="badge badge-danger">Inactive</span>
                }

                return <tr key={index}>
                            <td><span>{user.name} {user.family_name} </span></td>
                            <td className="text-center"> {enabledStatus} </td>
                            <td className="d-flex justify-content-center">
                                {tableButtonsArray.map( button => {
                                    return <Button   
                                                key={button.id}
                                                elementType={button.elementType}
                                                elementConfig={button.elementConfig}
                                                label={button.label}
                                                styling={button.styling}
                                                icon={button.icon}
                                                toggle={user.Enabled === true ? 1 : 0}  // Switch Button based on User Status, send to Button Component
                                                clicked={ (event) => props.buttonHandler(event, index)}
                                            /> 
                                })}
                            </td>
                        </tr>
            })
        )
    }

    return (
        <Layout>
            <table className="table table-filemanager">
                <thead className="thead">
                    <tr>
                    {tableHeader}
                    </tr>
                </thead>
                <tbody className="table-body">
                    {users}
                </tbody>
            </table>
            <Spinner name='loading' loading={props.loading}/>
        </Layout>
    )
}

export default UserTable;