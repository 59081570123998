// Redux ACTION FILE
import * as actionTypes from './actionTypes';
import loading from './loading';
import initialization from './initialization';
// AWS Cognito Authenticate Process
import { CognitoUser, CognitoUserPool, CognitoUserAttribute, AuthenticationDetails, CognitoRefreshToken } from 'amazon-cognito-identity-js';
//import AWS from 'aws-sdk';
import poolData from './cognito-pool-data.json';

// Cognito User Pool
const userPool = new CognitoUserPool(poolData);

// Action Functions
export const authStart = (username) => {
    return {
        type: actionTypes.AUTHENTICATE_START,
        idToken: null,
        refreshToken: null,
        tokenExpire: null,
        userID: username,
        name: null,
        family_name: null,
        email: null,
        groups: null,
        error: null,
        password_reset: false
  }
}
export const authSuccess = (idToken, refreshToken, UserInfo) => {
    return {
        type: actionTypes.AUTHENTICATE_SUCCESS,
        idToken: idToken,
        refreshToken: refreshToken,
        tokenExpire: UserInfo.tokenExpire,
        userID: UserInfo.username,
        name: UserInfo.name,
        family_name: UserInfo.family_name,
        email: UserInfo.email,
        groups: UserInfo.groups,
        password_reset: false
    }
}
export const authFailure = (error) => {
    return {
        type: actionTypes.AUTHENTICATE_FAILURE,
        idToken: null,
        refreshToken: null,
        userID: null,
        name: null,
        family_name: null,
        email: null,
        email_verified: null,
        groups: null,
        error: error,
        password_reset: false
    }
}
// These two dispatches are for AdminCreate Password
export const passwordResetStart = (username, name, family_name, email) => {
    return {
        type: actionTypes.PASSWORD_RESET_START,
        userID: username,
        name: name,
        family_name: family_name,
        email: email,
        password_reset: true
    }
}

export const passwordResetSuccess = () => {
    return {
        type: actionTypes.PASSWORD_RESET_SUCCESS,
        password_reset: false
    }
}

export const signOut = () => {
    userPool.getCurrentUser().signOut()
    return {
        type: actionTypes.AUTHENTICATE_LOGOUT,
    }
}

export const regStart = () => {
    return {
      type: actionTypes.REGISTRATION_START,
    }
}

export const regFailure = () => {
    return {
      type: actionTypes.REGISTRATION_FAILURE,
    }
}

export const verifyStart = ( username, aws_status ) => {
    console.log(aws_status)
    return {
      type: actionTypes.VERIFY_START,
      userID: username,
    }
}

export const verifySuccess = (email_verified) => {
    return {
        type: actionTypes.VERIFY_SUCCESS,
    }
}

export const verifyFailure = (error) => {
    return {
        type: actionTypes.VERIFY_FAILURE,
        error: error,
    }
}





//Register User
export const register = (username, firstname, lastname, email, password) => {
    return dispatch => {
        dispatch(regStart(true));

        const attributeList = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: email,
            }),
            new CognitoUserAttribute({
                Name: 'name',
                Value: firstname,
            }),
            new CognitoUserAttribute({
                Name: 'family_name',
                Value: lastname,
            })
        ]
        // Username must be unique in a pool, and cant be a valid email format
        // To log in with email, make sure it is set as an alias attribute in Cognito
        // More info: http://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html#user-pool-settings-usernames

        userPool.signUp(username, password, attributeList, null, (error, response) => {
            if (error) {
                console.log(error);
                dispatch(regFailure());
            }
            else {
                console.log(response, "Username: ", response.user.username);
                dispatch(verifyStart(response.user.username));
            }
        })
    }
}

// Verify registered User
export const verify = (username, verificationCode) => {
    return dispatch => {
        //Redux Verify Start
        dispatch(verifyStart(username, false));

        const userData = {
            Username: username,
            Pool: userPool,
        }

        const cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(verificationCode, true, (error, response) => {
            if (error) {
                console.log(error);
                dispatch(verifyFailure(error.code));
            }
            else {
                console.log('Success: ', response);
                dispatch(verifySuccess(true));
            }
        })
    }
}

// Reset Password
export const resetPassword = (username) => {
    return dispatch => {
        console.log('[resetPassword]');
        // Cognito User Setup
        const userData = {
            Username: username,
            Pool: userPool,
        }

        
        const cognitoUser = new CognitoUser(userData);
        // call forgotPassword on cognitoUser
        cognitoUser.forgotPassword({
            onSuccess: function(result) {
                dispatch(passwordResetStart(username, null, null, null));
            },
            onFailure: function(err) {
                console.log(err);
            },
            // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
            // Brings up a Browser Prompt
            // inputVerificationCode() { 
            //     var verificationCode = prompt('Please input verification code ', '');
            //     var newPassword = prompt('Enter new password ', '');
            //     cognitoUser.confirmPassword(verificationCode, newPassword, this);
            // }
        });
    }
}

// Confirm Password
export const confirmPassword = (username, newPassword, verificationCode) => {
    return dispatch => {
        console.log('[ConfirmPassword]');
        const userData = {
            Username: username,
            Pool: userPool,
        }
        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onFailure(err) {
                console.log(err);
                //RWC add a failure dispatch
            },
            onSuccess() {
                dispatch(passwordResetSuccess());
                console.log("Success");
            },
        });
    }
}

// Asynchronous Code reaching out to AWS (Redux+Thunk)
export const authenticate = (username, password, newPassword) => {
    return dispatch => {
        //Redux Authenticate Start
        dispatch(loading(true));
        dispatch(authStart(username));

        // AWS Cognito specific login
        const authenticationData = {
            Username: username,
            Password: password
        }

        const authDetails = new AuthenticationDetails(authenticationData);

        const userData = {
            Username: username,
            Pool: userPool,
        }

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.authenticateUser(authDetails, {
            onSuccess: function (response) {
                console.log('[AuthUser Success]', response)
                const UserInfo = {  username: response.idToken.payload['cognito:username'],
                                    name: response.idToken.payload['name'],
                                    family_name: response.idToken.payload['family_name'],
                                    email: response.idToken.payload['email'],
                                    groups: response.idToken.payload['cognito:groups'],
                                    email_verified: response.idToken.payload['email_verified'],
                                    tokenExpire: response.idToken.payload['exp']}
                dispatch(passwordResetSuccess());
                dispatch(authSuccess(response.idToken.jwtToken, response.refreshToken.token, UserInfo));
                dispatch(loading(false));
            },
            onFailure: function (error) {
                console.log(error.code);
                if (error.code === 'NotAuthorizedException') {
                    dispatch(authFailure('Incorrect Username or Password'));
                    console.log('[Authenticate onFailure]', error.code)
                }
                if (error.code === undefined) {
                    dispatch(authFailure('Reset your Password'));
                    console.log('[Authenticate onFailure]')
                }
            },
            newPasswordRequired: function (userAttributes,requiredAttributes) {
                dispatch(passwordResetStart(username, userAttributes.name, userAttributes.family_name, userAttributes.email));
                dispatch(loading(false));
                
                // User was signed up by an admin and must provide new 
                // password and required attributes, if any, to complete 
                // authentication.

                // userAttributes: object, which is the user's current profile. It will list all attributes that are associated with the user. 
                // Required attributes according to schema, which don’t have any values yet, will have blank values.
                // requiredAttributes: list of attributes that must be set by the user along with new password to complete the sign-in.

                
                // Get these details and call 
                // newPassword: password that user has given
                // attributesData: object with key as attribute name and value that the user has given.
                // the api doesn't accept this field back

                delete userAttributes.email_verified;
                cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this)
            },
        })
    }
}

export const getCurrentUser = () =>  {
    return dispatch => {
        //Redux Authenticate Start
        dispatch(initialization(false));  // User ID is not valid, we are not initialized
        dispatch(authStart());
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession( (error, response) => {
                //console.log('getCurrentUser Session:',response);

                if (error) {
                    console.log(error.message);
                    dispatch(authFailure(null, error.code));
                    dispatch(initialization(true));
                    dispatch(loading(false));
                    return;
                }
                const UserInfo = {  username: response.idToken.payload['cognito:username'],
                                    name: response.idToken.payload['name'],
                                    family_name: response.idToken.payload['family_name'],
                                    email: response.idToken.payload['email'],
                                    groups: response.idToken.payload['cognito:groups'],
                                    email_verified: response.idToken.payload['email_verified'],
                                    tokenExpire: response.idToken.payload['exp']}

                //Call reducer authSuccess
                dispatch(authSuccess(response.idToken.jwtToken, response.refreshToken.token, UserInfo));
                dispatch(initialization(true));

                // cognitoUser.getUserAttributes( (err, result) => {
                //     if (err) {
                //         alert(err);
                //         return;
                //     }
                //     // Quick Loop to list attributes
                //     /* for (let i = 0; i < result.length; i++) {
                //         console.log('attribute ' + result[i].getName() + ' has value ' + result[i].getValue());
                //     } */
                // });

            });
        } else {
            //dispatch(authFailure('Session Validity Fail'));
            dispatch(authFailure(null));
            dispatch(initialization(true));
            
        }
    }
}

// Token Refesh Promise, Does not update State
export const tokenRefreshPromise = (refreshToken) => {
    return new Promise( (resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession( (error, response) => {
                if (error) {
                    console.log('Error Message', error.message);
                    console.log('token refresh fail 1');
                    reject(error.message);
                }
                //console.log('Refresh session validity: ' + response.isValid());
                console.log('tokenRefreshPromise', response)
                if ( response.isValid() ) {
                    const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
                    // Async Call
                    cognitoUser.refreshSession(token, (err, response) => { 
                        if (err) {
                            console.log(err)
                            console.log('token refresh fail 2')
                        }
                        else {
                            console.log('TOKEN REFRESH');
                            resolve(response);
                        }
                    });
                }
                else reject(response)
            });
        }
    });
}

export const logout = () => {
    return dispatch => {
        dispatch(loading(true));
        dispatch(signOut());
        dispatch(loading(false));
    }
}
