export const InputValidityCheck = (value ,rules) => {

    let isValid = false;
    if (!rules) {
        return true;
        }
    if (rules.required) {
        isValid = value.trim() !== '';
    }
    if (rules.minLength) {
        isValid = (value.length >= rules.minLength) && isValid;
    }
    if (rules.maxLength) {
        isValid = (value.length <= rules.maxLength) && isValid;
    }
    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.Password) {
        const pattern = /^(?=.*\d)(?=.*[A-Z])/;  // Matches 1 number and 1 Caps
        isValid = pattern.test(value) && (value.length >= 8) && isValid
    }
    return (isValid);
 }