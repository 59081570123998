import React from 'react';
import Layout from '../../HOC/Layout';
import Icon from './Icon';

const LoadingButton = (props) => {
    let inputElement = null;
    const pbar = { width: props.pBarWidth + '%' };

    switch(props.elementType) {
        case ('button'):
            inputElement = 
                <button className={props.styling} {...props.elementConfig} disabled={props.disabled} onClick={props.clicked}>
                    <Icon icon={props.icon}></Icon>
                    {props.label}
                </button>     
            break;
        default:
            inputElement = <button onClick={props.clicked}>{props.name}</button>
            break;
    }

    if(props.downloading && props.switch)
        return (
            <div className="progress-wrapper">
                <div className="progress">
                    <div className="progress-bar" style={pbar}></div>
                </div> 
            </div>
            )
    else
        return (
            <Layout>
                {inputElement}
            </Layout>
        ) 
}

export default LoadingButton;