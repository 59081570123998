export default {
    userForm: {
        username: {
            elementType: 'input', 
            elementConfig: { type: 'text', placeholder: 'Username', icon: 'User', name: 'username'} , 
            value: '',
            styling: ['form-control login-control'],
            validation: { required: true, minLength: 6 },
            valid: false,
            touched: false
        }
    },
    confirmForm: {
        newPassword: { 
            elementType: 'input', 
            elementConfig: { type: 'password', placeholder: 'New Password', icon: 'Lock', name: 'newPassword'} , 
            value: '',
            styling: ['form-control login-control', 'form-control login-control invalid-input'],
            validation: { required: true, minLength: 8 },
            valid: false,
            touched: false
        },
        confirmPassword: { 
            elementType: 'input', 
            elementConfig: { type: 'password', placeholder: 'Confirm Password', icon: 'Lock', name: 'confirmPassword'} , 
            value: '',
            styling: ['form-control login-control', 'form-control login-control invalid-input'],
            validation: { required: true, minLength: 8 },
            valid: false,
            touched: false
        },
        verificationCode: {
            elementType: 'input', 
            elementConfig: { type: 'text', placeholder: 'Verification Code', icon: 'Key', name: 'verificationCode' } , 
            value: '',
            styling: ['form-control login-control', 'form-control login-control invalid-input'],
            validation: { required: true, minLength: 6, isNumeric: true },
            valid: false,
            touched: false
        } 
    },
    loginButton: {
        elementType: 'button',
        elementConfig: { type: 'submit', name: 'login'},
        label: 'Login',
        styling:  'btn btn-success btn-block text-uppercase',
        icon: 'SignIn'
    },

    submitButton: {
        elementType: 'button',
        elementConfig: { type: 'submit', name: 'submit'},
        label: 'Submit',
        styling:  'btn btn-success btn-block text-uppercase',
    },
    formValid: false,
    redirect: false,
    message: null
}