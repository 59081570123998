import React from 'react';
// UI Components
import Input from '../../Components/UI/Input';
import Button from '../../Components/UI/Buttons';
import Spinner from '../../Components/UI/Spinner';
import LoadingButton from '../../Components/UI/LoadingButton';

const FileTable = (props) => {

    //Create the Buttons dynamically
    const folderButtonsArray = [];
    for (let key in props.folderButtons) {
        folderButtonsArray.push( {id: key, 
                            elementType: props.folderButtons[key].elementType, 
                            elementConfig: props.folderButtons[key].elementConfig,
                            label: props.folderButtons[key].label,
                            styling: props.folderButtons[key].styling,
                            icon: props.folderButtons[key].icon })
    };
    
    // Columns of the Table
    const tableHeader = props.Header.map( header => <th key={header.label} style={header.style}>{header.label}</th>);
    
    let folders;
    // If we have Folders, render JSX
    if (props.Folders) {
        folders = (
            props.Folders.map( folder => {
                return <tr key={folder.Prefix}>
                            <td></td>
                            <td><span>{folder.Folder}</span></td>
                            <td>--</td>
                            <td></td>
                            <td className="d-flex justify-content-center">
                                {folderButtonsArray.map( button => {
                                    return <Button   
                                                key={button.id}
                                                elementType={button.elementType}
                                                elementConfig={button.elementConfig}
                                                label={button.label}
                                                styling={button.styling}
                                                icon={button.icon}
                                                clicked={(event, key, label) => props.buttonHandler(event, folder.Prefix, folder.Folder)}
                                            /> 
                                    })}
                                </td>
                        </tr>
            })
        )
    }

    let files;
    // If we have Files, render JSX
    if (props.Files) {
        files = (
            props.Files.map( file => {
                if (file.File)
                    return <tr key={file.Key}>
                                {/* Radio Buttons */}
                                <td>
                                    <Input  
                                        elementType={props.Controls.select.elementType}
                                        elementConfig={props.Controls.select.elementConfig}
                                        name={file.File}
                                        value={file.Key}
                                        valid={props.Controls.select.valid}
                                        validation={props.Controls.select.validation}
                                        touched={props.Controls.select.touched}
                                        styling={props.Controls.select.styling}
                                        checked={props.Checked.key === file.Key}
                                        changed={(event, key, label) => props.buttonHandler(event, file.Key, file.File)}
                                    />  
                                </td>
                                {/* Files */}
                                <td><span>{file.File}</span></td>
                                <td>{file.ByteSize}</td>
                                <td>{file.UploadDate}</td>
                                <td className="d-flex justify-content-center">
                                    {/* View Button */}
                                    <Button 
                                        elementType={props.viewButton.elementType}
                                        elementConfig={props.viewButton.elementConfig}
                                        switch={file.Key === props.fileKey}
                                        downloading={props.downloading}
                                        pBarWidth={props.pBarWidth}
                                        label={props.viewButton.label}
                                        styling={props.viewButton.styling}
                                        icon={props.viewButton.icon}
                                        clicked={(event, key, label) => props.buttonHandler(event, file.Key, file.File)}
                                    /> 
                                    {/* Download Button */}
                                    <LoadingButton 
                                        elementType={props.downloadButton.elementType}
                                        elementConfig={props.downloadButton.elementConfig}
                                        switch={file.Key === props.fileKey}
                                        downloading={props.downloading}
                                        pBarWidth={props.pBarWidth}
                                        label={props.downloadButton.label}
                                        styling={props.downloadButton.styling}
                                        icon={props.downloadButton.icon}
                                        clicked={(event, key, label) => props.buttonHandler(event, file.Key, file.File)}
                                    /> 
                                </td>
                            </tr> 
                else return null
            })
        )
    }

    return (
        <div className="row table-container">
            <table className="table table-filemanager">
                <thead className="thead">
                    <tr>
                    {tableHeader}
                    </tr>
                </thead>
                <tbody className="">
                    {folders}
                    {files}
                </tbody>
            </table>
            <Spinner name='loading' loading={props.loading}/>
        </div>
    )
}

export default FileTable;