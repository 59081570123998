// Holds Containers and Components for the App
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../Home/Home';
import Profile from '../CloudManager/Profile';
import FileManager from '../CloudManager/FileManager';
import ClientFileManager from './ClientFileManager';
import ClientManager from '../CloudManager/ClientManager';
import HeaderBar from '../Nav/HeaderBar'; //Top Side Navigation
import Toolbar from '../Nav/Toolbar';  //Left Side Navigation
import AdminRoute from '../../HOC/AdminRoute';
import NewClient from './NewClient';

const CloudManager = () => {
    return (
        <div className="body-wrapper">
            <HeaderBar />
            <main className="main-container">
            <Toolbar />
            <div className="main-wrapper d-block">
                <Switch>
                    <Route path="/home" exact component={Home}/>
                    <Route path="/profile" exact component={Profile}/>
                    <Route path="/filemanager/" exact component={FileManager}/>
                    <Route path="/filemanager/:folder" component={FileManager}/>
                    <AdminRoute path="/clientfilemanager/" exact component={ClientFileManager}/>
                    <AdminRoute path="/clientfilemanager/:folder" component={ClientFileManager}/>
                    <AdminRoute path="/clientmanager/" exact component={ClientManager}/>
                    <AdminRoute path="/clientmanager/newclient" exact component={NewClient}/>
                    <Route path="*" component={() => "404 NOT FOUND"} />
                </Switch>
            </div>
            </main>
        </div>
    )
}

export default CloudManager;