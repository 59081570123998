export default {
    newClientForm: {
        firstname: {
            elementType: 'input', 
            elementConfig: { type: 'text', placeholder: 'First Name', icon: 'User', name: 'firstname' } , 
            value: '',
            styling: ['form-control login-control', 'form-control login-control invalid-input'],
            validation: { 
                required: 'true',
                minLength: 2,
                maxLength: 12
            },
            valid: false,
            touched: false
        },
        lastname: {
            elementType: 'input', 
            elementConfig: { type: 'text', placeholder: 'Last Name', icon: 'User', name: 'lastname', } , 
            value: '',
            styling: ['form-control login-control', 'form-control login-control invalid-input'],
            validation: { 
                required: 'true',
                minLength: 2,
                maxLength: 12
            },
            valid: false,
            touched: false
        },
       username: {
            elementType: 'input', 
            elementConfig: { type: 'text', placeholder: 'Username', icon: 'UserCog', name: 'username', } , 
            value: '',
            styling: ['form-control login-control', 'form-control login-control invalid-input'],
            validation: { 
                required: 'true',
                minLength: 6,
                maxLength: 25
            },
            valid: false,
            touched: false
        },
        email: {
            elementType: 'input', 
            elementConfig: { type: 'email', placeholder: 'Email', icon: 'Mail', name: 'email', } , 
            value: '',
            styling: ['form-control login-control', 'form-control login-control invalid-input'],
            validation: { 
                required: 'true',
                isEmail: true
            },
            valid: false,
            touched: false
        },
    },
    formValid: false,
    buttons: {
        create: {
            name: 'Create',
            elementType: 'button',
            elementConfig: { type: 'submit', name: 'Create'},
            label: 'Create',
            styling:  'btn btn-primary filetool-btn mx-1 ',
            icon: 'Save',
            disabled: false
        },
        cancel: {
            name: 'Cancel',
            elementType: 'button',
            elementConfig: { type: 'button', name: 'Cancel'},
            label: 'Cancel',
            styling:  'btn btn-danger filetool-btn mx-1',
            icon: 'Times'
        }
    }
}