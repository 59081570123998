import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';

// Reducter Files
import authenticateReducer, { authFailure } from './Store/Reducers/authenticate';
import loadingReducer from './Store/Reducers/loading';
import initializationReducer from './Store/Reducers/initialization';
import timersReducer from './Store/Reducers/timers';

import { authSuccess, tokenRefreshPromise } from './Store/Actions/authentication';

import axios from 'axios';
// Axios BaseURL
axios.defaults.baseURL='https://u8eklpcw9k.execute-api.us-west-2.amazonaws.com/development'

// (React Lecture 162)
axios.interceptors.request.use( (config) => {
    // Calculate the Token Expiration Time
    const { refreshToken, tokenExpire } = store.getState().authenticate;
    const timeThreshold = Math.floor(new Date().getTime() / 1000 + 3); // 5 minutes from now
    console.log('TokenExpire', timeThreshold - tokenExpire);

    if (refreshToken && ( timeThreshold > tokenExpire)) {
        return tokenRefreshPromise(store.getState().authenticate.refreshToken).then( (response) => {
            // Set new idToken in the REQUEST HEADER
            console.log('[Inter] Response', response);
            config.headers.Authorization = response.idToken.jwtToken;
            // Need to dispatch an AUTH_SUCCESS
            const UserInfo = {  username: response.idToken.payload['cognito:username'],
                name: response.idToken.payload['name'],
                family_name: response.idToken.payload['family_name'],
                email: response.idToken.payload['email'],
                groups: response.idToken.payload['cognito:groups'],
                email_verified: response.idToken.payload['email_verified'],
                tokenExpire: response.idToken.payload['exp'] }
            store.dispatch(authSuccess(response.idToken.jwtToken, response.refreshToken.token, UserInfo))
            return Promise.resolve(config)
        }).catch( (error) => {
            console.log('[Inter] Token expire...need to redirect')
            console.log(error);
            store.dispatch(authFailure(error))
        })
    }
    else return config
    }
, (error) => {
    return Promise.reject(error);
});

// Combine the Reducers, Create an Object (React Lecture 253)
const rootReducer = combineReducers({
    loading: loadingReducer,
    authenticate: authenticateReducer,
    initialization: initializationReducer,
    timers: timersReducer
});

// Added custom middleware (React Lecture 274)
const refreshMiddleware = store => next => action => {
    // console.log('[Middleware next state', store.getState() );
    // console.log('[Middleware dispatch', store.dispatch );
    // console.log('[Middleware action', action );
    next(action);
}

// Added this composer for// DEVTOOL SUPPORT (React Lecture 270)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Creates Redux Store, input is Reducer
// Reducer simply returns the new State
const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(refreshMiddleware, thunk)
));

const app = (
    <Provider store={store}>
      <App />
    </Provider>
  )

ReactDOM.render( app, document.getElementById('root'));
registerServiceWorker();
