// Helper function
// Process URLs using RegEx
// The resource Name is the Application Page, i.e. Filemanager, ClientManager, etc.
// The currentURL is the name of the CurrentURL, and 
// The USERID is the person currently logged in

export default (UTCDate) => {

    const weekdays = new Array(7);
        weekdays[0] = "Sun";
        weekdays[1] = "Mon";
        weekdays[2] = "Tues";
        weekdays[3] = "Wed";
        weekdays[4] = "Thur";
        weekdays[5] = "Friday";
        weekdays[6] = "Saturday";

    const months = new Array(12);
        months[0] = "January";
        months[1] = "February";
        months[2] = "March";
        months[3] = "April";
        months[4] = "May";
        months[5] = "June";
        months[6] = "July";
        months[7] = "August";
        months[8] = "September";
        months[9] = "October";
        months[10] = "November";
        months[11] = "December";

    const month = months[UTCDate.getMonth()];
    const day = weekdays[UTCDate.getDay()];
    const date = UTCDate.getDate();

    var year = UTCDate.getFullYear();

    return  day + ", " + month + " " + date + ", " + year;
}